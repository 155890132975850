import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
//eslint-disable-next-line
import store from "@/store";
// import { checkMotionPermission } from "@/utils/Motion";
import pwa from "@/views/pwa";
import Loading from "@/views/Loading";


const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
};
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/pwa",
    name: "Pwa",
    component: pwa
  },
  {
    path: "/login",
    name: "Login",
    props: true,
    component: () => import(/* webpackChunkName: "system" */ "../views/Login.vue")
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    props: true,
    component: () => import(/* webpackChunkName: "system" */ "../views/forgetPassword.vue")
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import(/* webpackChunkName: "system" */ "../views/Signup.vue")
  },
  {
    path: "/gameIntro",
    name: "gameIntro",
    component:
      () => ({
        // The component to load (should be a Promise)
        component: import(/* webpackChunkName: "system" */ "../views/gameIntro.vue"),
        // A component to use while the async component is loading
        loading: Loading,
        // A component to use if the load fails
        error: Loading,
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      })

  },
  {
    path: "/consent",
    name: "consent",
    component: () => import(/* webpackChunkName: "system" */ "../views/consent.vue")
  },
  {
    path: "/enterSn",
    name: "enterSn",
    component:
      () => ({
        // The component to load (should be a Promise)
        component: import(/* webpackChunkName: "system" */ "../views/enterSn.vue"),
        // A component to use while the async component is loading
        loading: Loading,
        // A component to use if the load fails
        error: Loading,
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      }),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/teamStart",
    name: "teamStart",
    component:
      () => ({
        // The component to load (should be a Promise)
        component: import(/* webpackChunkName: "team" */ "../views/team/teamStart.vue"),
        // A component to use while the async component is loading
        loading: Loading,
        // A component to use if the load fails
        error: Loading,
        // Delay before showing the loading component. Default: 200ms.
        delay: 200,
        // The error component will be displayed if a timeout is
        // provided and exceeded. Default: Infinity.
        timeout: 3000
      }),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/teamCreate",
    name: "teamCreate",
    component: () => import(/* webpackChunkName: "team" */ "../views/team/teamCreate.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/teamReForm",
    name: "teamReForm",
    component: () => import(/* webpackChunkName: "team" */ "../views/team/teamReForm.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/characters",
    name: "characters",
    component: () => import(/* webpackChunkName: "team" */ "../views/team/characters.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/qrCode",
    name: "qrCode",
    component: () => import(/* webpackChunkName: "team" */ "../views/team/qrCode.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/qrCodeLeader",
    name: "qrCodeLeader",
    component: () => import(/* webpackChunkName: "team" */ "../views/team/qrCodeLeader.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/openScene",
    name: "openScene",
    component: () => import(/* webpackChunkName: "home" */ "../views/openScene.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/homeMap",
    name: "homeMap",
    meta: {
      keepAlive: false,
      requiredAuth: true
    },
    props: true,
    component: () => import(/* webpackChunkName: "home" */ "../views/stageMap.vue")
  },
  {
    path: "/qa",
    name: "qa",
    component: () => import(/* webpackChunkName: "system" */ "../views/qa.vue")
  },
  {
    path: "/nanyang",
    name: "nanyang",
    component: () => import(/* webpackChunkName: "nanyang" */ "../views/nanyang.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/cks",
    name: "cks",
    component: () => import(/* webpackChunkName: "cks" */ "../views/cks.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/danceStandBy",
    name: "danceStandBy",
    component: () => import(/* webpackChunkName: "dance" */ "../views/danceStandBy.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/dance",
    name: "dance",
    component: () => import(/* webpackChunkName: "dance" */ "../views/dance.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/danceVideo",
    name: "danceVideo",
    component: () => import(/* webpackChunkName: "dance" */ "../views/danceVideo.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/danceRule",
    name: "danceRule",
    props: true,
    component: () => import(/* webpackChunkName: "dance" */ "../views/danceRule.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/danceResult",
    name: "danceResult",
    component: () => import(/* webpackChunkName: "dance" */ "../views/danceResult.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/mapArrive",
    name: "mapArrive",
    component: () => import(/* webpackChunkName: "home" */ "../views/mapArrive.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/fgCompass",
    name: "fgCompass",
    component: () => import(/* webpackChunkName: "fg" */ "../views/fgCompass.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/ximen",
    name: "ximen",
    component: () => import(/* webpackChunkName: "ximen" */ "../views/ximen.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/park228",
    name: "park228",
    component: () => import(/* webpackChunkName: "park228" */ "../views/park228.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/dating",
    name: "dating",
    component: () => import(/* webpackChunkName: "dating" */ "../views/dating.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/datingRule",
    name: "datingRule",
    component: () => import(/* webpackChunkName: "dating" */ "../views/datingRule.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/datingResult",
    name: "datingResult",
    component: () => import(/* webpackChunkName: "dating" */ "../views/datingResult.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/fightingResult",
    name: "fightingResult",
    component: () => import(/* webpackChunkName: "park228" */ "../views/fightingResult.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/fightingRule",
    name: "fightingRule",
    component: () => import(/* webpackChunkName: "park228" */ "../views/fightingRule.vue")
  },
  {
    path: "/fightingStandBy",
    name: "fightingStandBy",
    component: () => import(/* webpackChunkName: "park228" */ "../views/fightingStandBy.vue")
  },
  {
    path: "/fighting",
    name: "fighting",
    component: () => import(/* webpackChunkName: "park228" */ "../views/fighting.vue")
  },
  {
    path: "/fightingBystand",
    name: "fightingBystand",
    component: () => import(/* webpackChunkName: "park228" */ "../views/fightingBystand.vue")
  },
  {
    path: "/csh",
    name: "csh",
    component: () => import(/* webpackChunkName: "park228" */ "../views/csh.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/food/:kind",
    name: "food",
    props: true,
    component: () => import(/* webpackChunkName: "food" */ "../views/food.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/taipeiStation",
    name: "taipeiStation",
    component: () => import(/* webpackChunkName: "taipei" */ "../views/taipeiStation.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/ghostRule/:page?",
    name: "ghostRule",
    props: true,
    component: () => import(/* webpackChunkName: "taipei" */ "../views/ghostRule.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/ghost",
    name: "ghost",
    component: () => import(/* webpackChunkName: "taipei" */ "../views/ghost.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "system" */ "../views/About.vue")
  },
  {
    path: "/storyAlbum",
    name: "story",
    component: () => import(/* webpackChunkName: "final" */ "../views/story.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/scoreResult",
    name: "scoreResult",
    component: () => import(/* webpackChunkName: "final" */ "../views/scoreResult.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/winner",
    name: "winner",
    component: () => import(/* webpackChunkName: "final" */ "../views/winner.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/winnerVideo",
    name: "winnerVideo",
    component: () => import(/* webpackChunkName: "final" */ "../views/winnerVideo.vue")
  },
  {
    path: "/endScene",
    name: "endScene",
    component: () => import(/* webpackChunkName: "final" */ "../views/endScene.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/finalPanel",
    name: "finalPanel",
    component: () => import(/* webpackChunkName: "final" */ "../views/finalPanel.vue"),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "system" */ "../views/logout.vue")
  },
  {
    path: "/checkList",
    name: "checkList",
    component: () => import(/* webpackChunkName: "system" */ "../views/mobileTest.vue")
  },
  {
    path: "/mobiletest",
    redirect: '/test'
  },
  {
    path: "/test",
    name: "mobileTestOnly",
    component: () => import(/* webpackChunkName: "system" */ "../views/mobileTestOnly.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "system" */ "../views/About.vue")
  }
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("UpdateLatestPath", { to, from });
  store.dispatch("doCheckMotionPermission");
  store.dispatch("Video/fakeFullScreen", { status: false });
  // let path = store?.state?.Stage?.flags?.lock_path;
  // if(path && to.path!==path){
  //   next(path);
  //   return;
  // }
  if (to.meta.requiredAuth) {
      await store.dispatch("User/SyncUserStatus");
  }
  next();
});

export default router;
