<template>
  <div class="position-relative">
    請安裝ＰＷＡ
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>
<style scoped></style>
