export default [
  {
    status: "default", // default, unlock, clear
    unlock: false,
    step: [{ unlock: false }, { unlock: false }],
    finish: true,
    icon: {
      iconUrl: "/img/pin-cha4.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    },
    name: "南陽街",
    lockPosition: [25.044732, 121.51702],
    unlockPosition: [25.044661, 121.516125],
    // clearPosition: [25.045966, 121.518125],
    clearFlag: "stage_1_clear",
    clearPosition: [25.044653, 121.515868],
    herePosition: [25.045497, 121.515057],
    unlockFlag: "stage_1_default",
    hereUnlockFlag: "stage_1_phase1Clear",
    // clearIconPos: [25.047259, 121.517726],
    bounds: [
      [25.04682, 121.514989],
      [25.045597, 121.519591],
      [25.04334, 121.518913],
      [25.043415, 121.515007]
    ]
  },
  {
    unlock: false,
    finish: false,
    status: "default", // default, unlock, clear
    icon: {
      iconUrl: "/img/pin-cha5.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    },
    name: "中正紀念堂",
    lockPosition: [25.037833, 121.514461],
    unlockPosition: [25.036854, 121.517439],
    clearPosition: [25.040665, 121.516658],
    // clearIconPos: [25.045966, 121.518125],
    clearFlag: "stage_5_clear",
    unlockFlag: "stage_2_default",
    bounds: [
      [25.041192, 121.511419],
      [25.040496, 121.518086],
      [25.03878, 121.517602],
      [25.035476, 121.524269],
      [25.032348, 121.522579],
      [25.03089, 121.522272],
      [25.032705, 121.518276],
      [25.034781, 121.516747],
      [25.035687, 121.510786]
    ]
  },
  {
    // unlock: false,
    // finish: false,
    status: "default", // default, unlock, clear
    icon: {
      iconUrl: "/img/pin-cha7.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    },
    name: "中山堂",
    lockPosition: [25.043321, 121.510697],
    unlockPosition: [25.043052, 121.510361],
    clearPosition: [25.045175, 121.510708],
    clearFlag: "stage_6_clear",
    unlockFlag: "stage_3_default",
    // clearIconPos: [25.045966, 121.518125],
    bounds: [
      [25.046428, 121.503561],
      [25.045158, 121.509141],
      [25.045186, 121.512455],
      [25.041089, 121.512545],
      [25.041192, 121.511419],
      [25.041192, 121.511419],
      [25.042166, 121.5083],
      [25.043566, 121.502607]
    ]
  },
  {
    // unlock: false,
    // finish: false,
    status: "default", // default, unlock, clear
    icon: {
      iconUrl: "/img/pin-cha5.png",
      iconSize: [69, 102],
      iconAnchor: [34, 102]
    },
    name: "228 公園",
    lockPosition: [25.042212, 121.515288],
    unlockPosition: [25.041679, 121.514963],
    clearPosition: [25.043365, 121.516628],
    // clearIconPos: [25.045966, 121.518125],
    clearFlag: "stage_4_clear",
    unlockFlag: "stage_4_default",
    bounds: [
      [25.045186, 121.512455],
      [25.045169, 121.515005],
      [25.043415, 121.515007],
      [25.04334, 121.518913],
      [25.040496, 121.518086],
      [25.041089, 121.512545]
    ]
  },
  {
    status: "default",
    icon: {
      iconUrl: "/img/pin-cha0.png",
      iconSize: [69, 102],
      iconAnchor: [34, 102]
    },
    finish: false,
    name: "台北車站",
    position: [25.047207, 121.516971],
    bounds: [
      [25.049734, 121.512395],
      [25.048889, 121.515442],
      [25.048071, 121.520703],
      [25.045597, 121.519591],
      [25.046820, 121.514989],
      [25.045169, 121.515005],
      [25.045186, 121.512455]
    ]
  }
];
