import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["modal", "infoModal"])
  },
  methods: {
    ...mapActions(["setModal", "setInfoModal"]),
    closeModal() {
      this.setModal({ data: { content: "", show: false } });
      this.setInfoModal({ title: "", content: "", show: false });
    },
    showModal(content) {
      this.setModal({ data: { content, show: true } });
    },
    showInfoModal({ content, title }) {
      this.setInfoModal({ content, title, show: true });
    }
  }
};
