export default [
  {
    id: "stage1",
    stage: 1,
    unlockFlag: "stage_1_default",
    unlock: true,
    if_lock_goto: null,
    name: "高一上 南陽街<br>小希的故事",
    stage_lock: 1,
    local: [25.044661, 121.516125],
    localFlag: "stage_1_location",
    picture: "avatar4@2x.jpg",
    btnText: "讓小希說",
    link: "nanyang",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.044661,121.516125&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha4.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage1_f1",
    stage: 1,
    unlockFlag: "stage_1_phase1Clear",
    unlock: false,
    if_lock_goto: "stage1",
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.0455, 121.516304],
    picture: "avatar1@2x.jpg",
    btnText: "阿泉臭豆腐",
    link: "food/tofu",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.045500,121.516304&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage1_photo",
    stage: 1,
    unlockFlag: "stage_1_phase2Clear",
    if_lock_goto: "stage1",
    unlock: false,
    name: "南陽街拍照地點",
    local: [25.044661, 121.516125],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "nanyang",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage2",
    stage: 2,
    unlockFlag: "stage_2_default",
    unlock: true,
    if_lock_goto: null,
    name: "高一下 中正紀念堂<br>赤木的故事",
    local: [25.036854, 121.517439],
    localFlag: "stage_2_location",
    stage_lock: 2,
    picture: "avatar5@2x.jpg",
    btnText: "讓赤木說",
    link: "cks",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.036854,121.517439&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha5.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage2_f1",
    stage: 2,
    unlockFlag: "stage_2_phase1Clear",
    unlockFlag2: "stage_5_default",
    if_lock_goto: "stage2",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.034091, 121.523619],
    picture: "avatar1@2x.jpg",
    btnText: "杭州小籠湯包",
    link: "food/xiaolongbao",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.034091,121.523619&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage2_f2",
    stage: 2,
    unlockFlag: "stage_2_phase1Clear",
    unlockFlag2: "stage_5_default",
    if_lock_goto: "stage2",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.032116, 121.518581],
    picture: "avatar1@2x.jpg",
    btnText: "金峰滷肉飯",
    link: "food/porkrice",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.032116,121.518581&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage2_photo",
    stage: 2,
    unlockFlag: "stage_2_danceFinish",
    if_lock_goto: "stage2",
    unlock: false,
    name: "中正紀念堂拍照地點",
    local: [25.035013, 121.520907],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "cks",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage3",
    stage: 3,
    unlockFlag: "stage_3_default",
    unlock: true,
    if_lock_goto: null,
    name: "高二下 中山堂<br>春哥的故事",
    stage_lock: 3,
    local: [25.043052, 121.510361],
    localFlag: "stage_3_location",
    picture: "avatar7@2x.jpg",
    btnText: "讓春哥說",
    link: "csh",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.043052,121.510361&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha7.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage3_f1",
    stage: 3,
    unlockFlag: "stage_3_phase1Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage3",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.044248, 121.512319],
    picture: "avatar1@2x.jpg",
    btnText: "城中豆花伯",
    link: "food/douhua",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.044248,121.512319&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage3_f2",
    stage: 3,
    unlockFlag: "stage_3_phase1Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage3",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.042911, 121.511559],
    picture: "avatar1@2x.jpg",
    btnText: "世運食品",
    link: "food/olympiafoods",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.042911,121.511559&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage3_photo",
    stage: 3,
    unlockFlag: "stage_3_phase4Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage3",
    unlock: false,
    name: "中山堂拍照地點",
    local: [25.043364, 121.509901],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "csh",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage4",
    stage: 4,
    unlockFlag: "stage_4_default",
    unlock: true,
    if_lock_goto: null,
    name: "高三下 228公園<br>胖子的故事",
    stage_lock: 4,
    local: [25.041679, 121.514963],
    localFlag: "stage_4_location",
    picture: "avatar2@2x.jpg",
    btnText: "讓胖子說",
    link: "park228",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.041679,121.514963&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha2.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage4_f1",
    stage: 4,
    unlockFlag: "stage_4_phase1Clear",
    if_lock_goto: "stage4",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.042332, 121.514013],
    picture: "avatar1@2x.jpg",
    btnText: "公園號酸梅湯",
    link: "food/plum",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.042332,121.514013&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage4_f2",
    stage: 4,
    unlockFlag: "stage_4_phase1Clear",
    if_lock_goto: "stage4",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.044137, 121.512701],
    picture: "avatar1@2x.jpg",
    btnText: "明星西點麵包",
    link: "food/pastry",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.044137,121.512701&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage4_photo",
    stage: 4,
    unlockFlag: "stage_4_phase3Clear",
    if_lock_goto: "stage4",
    unlock: false,
    name: "228 公園拍照地點",
    local: [25.042336, 121.51583],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "park228",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage5",
    stage: 5,
    unlockFlag: "stage_5_default",
    unlock: true,
    if_lock_goto: "stage5",
    name: "高二上 北一女<br>白爛的故事",
    stage_lock: 5,
    local: [25.03887, 121.513],
    localFlag: "stage_5_location",
    picture: "avatar6@2x.jpg",
    btnText: "讓白爛說",
    link: "fgCompass",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.038870,121.513000&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha6.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage5_photo",
    stage: 5,
    unlockFlag: "stage_5_fgClear",
    if_lock_goto: "stage5",
    unlock: false,
    name: "北一女拍照地點",
    local: [25.039033, 121.51472],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "fgCompass",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage6",
    stage: 6,
    unlockFlag: "stage_6_default",
    unlock: true,
    if_lock_goto: "stage6",
    name: "高三上 西門町<br>花輪的故事",
    stage_lock: 6,
    local: [25.042456, 121.507745],
    picture: "avatar3@2x.jpg",
    btnText: "讓花輪說",
    link: "ximen",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.042456,121.507745&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha3.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage6_f1",
    stage: 6,
    unlockFlag: "stage_3_phase1Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage6",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.044717, 121.507245],
    picture: "avatar1@2x.jpg",
    btnText: "老天祿滷味",
    link: "food/lautianlu",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.044717,121.507245&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage6_f2",
    stage: 6,
    unlockFlag: "stage_3_phase1Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage6",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.043395, 121.507656],
    picture: "avatar1@2x.jpg",
    btnText: "阿宗麵線",
    link: "food/noodles",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.043395,121.507656&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage6_f3",
    stage: 6,
    unlockFlag: "stage_3_phase1Clear",
    unlockFlag2: "stage_6_default",
    if_lock_goto: "stage6",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.04262, 121.507874],
    picture: "avatar1@2x.jpg",
    btnText: "成都楊桃冰",
    link: "food/starfruit",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.042620,121.507874&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage6_photo",
    stage: 6,
    unlockFlag: "stage_6_datingFinish",
    if_lock_goto: "stage6",
    unlock: false,
    name: "西門町拍照地點",
    local: [25.044944, 121.504014],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "ximen",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  },
  {
    id: "stage7",
    stage: 7,
    unlockFlag: "stage_7_default",
    unlock: true,
    if_lock_goto: "stage7",
    name: "升大學暑假<br>台北車站",
    stage_lock: 7,
    local: [25.047207, 121.516971],
    picture: "avatar0@2x.jpg",
    btnText: "前往",
    link: "taipeiStation",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.047207,121.516971&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-cha0.png",
      iconSize: [74, 100],
      iconAnchor: [37, 100]
    }
  },
  {
    id: "stage7_f1",
    stage: 7,
    unlockFlag: "stage_7_default",
    if_lock_goto: "stage7",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.045672, 121.51378],
    picture: "avatar1@2x.jpg",
    btnText: "劉山東牛肉麵",
    link: "food/beef",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.045672,121.513780&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage7_f2",
    stage: 7,
    unlockFlag: "stage_7_default",
    if_lock_goto: "stage7",
    unlock: false,
    name: " <span>支線 美食任務 </span><span>小桃的回憶</span>",
    local: [25.046001, 121.513318],
    picture: "avatar1@2x.jpg",
    btnText: "福州世祖胡椒餅",
    link: "food/pepperCake",
    map_href: "https://www.google.com/maps/dir/?api=1&destination=25.046001,121.513318&travelmode=transit",
    icon: {
      iconUrl: "/img/pin-food.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    }
  },
  {
    id: "stage7_photo",
    stage: 7,
    unlockFlag: "stage_7_check1stPlace",
    if_lock_goto: "stage7",
    unlock: false,
    name: "台北火車站拍照地點",
    local: [25.04781, 121.517247],
    picture: "avatar-photo.jpg",
    btnText: "",
    link: "taipeiStation",
    map_href: "",
    icon: {
      iconUrl: "/img/pin-camara.png",
      iconSize: [70, 70],
      iconAnchor: [35, 70]
    },
    hideOnMap: true
  }
];
