import { sendMessage } from "../../utils/WebSocket";

const initialState = () => {
  return {
    winner: null,
    winner_user: null,
    shi_select_who: null,
    now_step: 0
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    winner: state => state.winner,
    winner_user: state => state.winner_user,
    shi_select_who: state => state.shi_select_who,
    now_step: state => state.now_step
  },
  mutations: {
    reset(state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    SET_SHI_SELECT_WHO(state, who) {
      state.shi_select_who = who;
    },
    SET_NOW_STEP(state, step) {
      state.now_step = step;
    },
    SET_WINNER: function(state, { winner, user }) {
      state.winner = winner;
      state.winner_user = user;
    }
  },
  actions: {
    setNowStep({ commit }, step) {
      commit("SET_NOW_STEP", step);
    },
    resetNowStep({ commit }) {
      commit("SET_NOW_STEP", 0);
    },
    stepNext({ commit, state }) {
      let now_step = state.now_step;
      let winner = state?.winner?.name || state?.winner;
      if (now_step == 0 && winner == "小希") {
        commit("SET_NOW_STEP", 1);
        return;
      } else if (now_step == 0 && winner == "白爛") {
        commit("SET_NOW_STEP", 2);
        return;
      } else {
        commit("SET_NOW_STEP", 3);
      }

      if (now_step == 1) {
        if (state?.winner?.name == "白爛") {
          commit("SET_NOW_STEP", 2);
        } else {
          commit("SET_NOW_STEP", 3);
        }
        return;
      }
      if (now_step == 2) {
        commit("SET_NOW_STEP", 3);
        return;
      }
    },
    shi_do_select_sync({ commit }, { data: { who } }) {
      commit("SET_SHI_SELECT_WHO", who);
      // dispatch('stepNext');
    },
    shi_do_select_done({ commit, dispatch }, { data: { who } }) {
      commit("SET_SHI_SELECT_WHO", who);
      dispatch("stepNext");
    },
    shi_do_select({ commit, state }, { token, who, stage, step }) {
      if (state.shi_select_who == who) {
        who = null;
      }
      commit("SET_SHI_SELECT_WHO", who);
      let data = {
        token: token,
        method: "ShiSelect",
        bonus_to_who: who,
        stage: stage,
        step: step
      };
      sendMessage(data);
    },
    setWinnerLocal: ({ commit }, { winner, user }) => {
      commit("SET_WINNER", { winner, user });
    },
    setWinner: ({ commit, rootState }, { winner, user, stage, step }) => {
      commit("SET_WINNER", { winner, user });

      const messageData = {
        method: "SetWinner",
        token: rootState.User.token,
        winner,
        stage,
        step,
        user
      };
      sendMessage(messageData);
    },
    setWinnerFromWebsocket: (context,payload) => {
      const character =payload?.data?.character;
      const user =payload?.data?.user;
      context.commit("SET_WINNER", { winner: character, user });
    }
  }
};
