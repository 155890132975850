import { sendMessage } from "../../utils/WebSocket";

export default {
  namespaced: true,
  state: {
    talk_list: {}
  },
  getters: {
    getTalkListByLabel: state => label => state.talk_list?.[label]?.sort((a, b) => a.id - b.id) || [],
    talk_list: state => state.talk_list
  },
  mutations: {
    reset(state) {
      state.talk_list = {};
    },
    update_talk_list(state, { label, talking }) {
      let temp = {};
      temp[label] = talking;

      state.talk_list = { ...state.talk_list, ...temp };
    }
  },
  actions: {
    updateTalkList({ commit }, { payload }) {
      commit("update_talk_list", payload);
    },
    sendTalk({ rootState }, { label, content, team_share, game_share }) {
      let character = rootState.User.character;
      if(content.length<=0) return;
      if (content.length > 50) {
        content = content.substr(0, 50);
      }
      const data = {
        token: rootState.User.token,
        method: "SendChat",
        character,
        label,
        content,
        team_share,
        game_share
      };
      sendMessage(data);
    },
    fetchTalk({ rootState }, { label, from }) {
      const data = {
        token: rootState.User.token,
        method: "FetchChat",
        label,
        from
      };
      sendMessage(data);
    }
  }
};
