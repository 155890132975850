import touchable from "@/store/modules/flag/touchable";
import global from "@/store/modules/flag/global";

export default {
  ...touchable,
  ...global,
  /// stage 1
  stage_1_location: [25.044661, 121.516125],
  stage_1_default: false,
  stage_1_step_1: false,
  stage_1_everyone_can_goto: false,
  stage_1_step_1_favor: false,
  stage_1_step_1_unlock: false,
  stage_1_step_2: false,
  stage_1_step_2_favor: false,
  stage_1_phase2Clear: false,
  stage_1_phase1Clear: false,
  stage_1_phase1ClearCard: false,
  stage_1_phase1Pass: false,
  stage_1_phase2Block: false,
  stage_1_check1stPlace: false,
  stage_1_check_1st_place: false,
  stage_1_clear: false,
  stage_1_photo_finished: false,
  stage_1_food_1: false,
  stage_1_food_1_clear: false,
  stage_1_phase3Clear: false,
  stage_1_step_3_favor: false,
  // stage 2
  stage_2_location: [25.036854, 121.517439],
  stage_2_default: false,
  stage_2_phase1Clear: false,
  stage_2_check1stPlace: false,
  stage_2_findDancePlace: false,
  stage_2_dancingRule: false,
  stage_2_goDancing: false,
  stage_2_phase2: false,
  stage_2_danceFinish: false,
  stage_2_dance_bonus: false,
  stage_2_danceWinner: "",
  stage_2_photo_finished: false,
  stage_2_finished: false,
  stage_2_phase4Clear: false,
  stage_2_clear: false,
  stage_2_food_1: false,
  stage_2_food_1_clear: false,
  stage_2_food_2: false,
  stage_2_food_2_clear: false,
  stage_2_step_1_favor: false,
  stage_2_findDancePlace_favor: false,
  stage_2_step_4_favor: false,
  stage_2_step_4_favor_over: false,
  //stage 3
  stage_3_location: [25.043052, 121.510361],
  stage_3_default: false,
  stage_3_phase1Clear: false,
  stage_3_step_1_favor: false,
  stage_3_step_2_favor: false,
  stage_3_step_3_favor: false,
  stage_3_check1stPlace: false,
  stage_3_phase2_find1: false,
  stage_3_phase2_find1_p1: false,
  stage_3_phase2_find1_p2: false,
  stage_3_phase2_find1_p3: false,
  stage_3_phase2_find1_clear: false,
  stage_3_phase2_find2: false,
  stage_3_phase2_find2_p1: false,
  stage_3_phase2_find2_p2: false,
  stage_3_phase2_find2_p3: false,
  stage_3_phase2_find2_clear: false,
  stage_3_phase2_find3: false,
  stage_3_phase2_find3_p1: false,
  stage_3_phase2_find3_p2: false,
  stage_3_phase2_find3_p3: false,
  stage_3_phase2_find3_clear: false,
  stage_3_phase2Clear: false,
  stage_3_phase3Clear: false,
  stage_3_phase4OpenBag: false,
  stage_3_phase4OpenSheet: false,
  stage_3_phase4Clear: false,
  stage_3_clear: false,
  stage_3_photo_clear: false,
  stage_3_food_1: false,
  stage_3_food_1_clear: false,
  stage_3_food_2: false,
  stage_3_food_2_clear: false,
  stage_3_phase5Clear: false,
  stage_3_step_5_favor: false,
  stage_3_step_5_hug_music: false,
  // stage 4
  stage_4_location: [25.041679, 121.514963],
  stage_4_default: false,
  stage_4_step_1: false,
  stage_4_phase1Clear: false,
  stage_4_phase1Clear_bonus: false,
  stage_4_check1stPlace: false,
  stage_4_goFighting: false,
  stage_4_fightingRule: false,
  stage_4_phase2: false,
  stage_4_fightingFinish: false,
  stage_4_fightingWinner: "",
  stage_4_phase3Clear: false,
  stage_4_phase3Clear_bonus: false,
  stage_4_clear: false,
  stage_4_photo_clear: false,
  stage_4_food_1: false,
  stage_4_food_1_clear: false,
  stage_4_food_2: false,
  stage_4_food_2_clear: false,
  stage_4_phase4Clear: false,
  stage_4_step_4_favor: false,
  //stage 5
  stage_5_check1stPlace: false,
  stage_5_default: false,
  stage_5_video_0_ended: false,
  stage_5_video_1_ended: false,
  stage_5_video_2_ended: false,
  stage_5_video_1_hintIndex: 0,
  stage_5_video_2_hintIndex: 0,
  stage_5_video_3_hintIndex: 0,
  stage_5_compass_screen_center: false,
  stage_5_compass_touch: false,
  stage_5_compass_lock: false,
  stage_5_compass_show_hint: false,
  stage_5_continue_btn: false,
  stage_5_place_0_winner_user: 0,
  stage_5_place_1_winner_user: 0,
  stage_5_place_2_winner_user: 0,

  stage_5_compass: 0,
  stage_5_blood: null,
  stage_5_fgClear: false,
  stage_5_clear: false,
  stage_5_uploaded: false,
  stage_5_photo_finished: false,
  stage_5_clear_favor: false,
  // stage 6
  stage_6_check1stPlace: false,
  stage_6_datingRule: false,
  stage_6_dating_start_unlock: false,
  stage_6_goDating: false,
  stage_6_datingFinish: false,
  stage_6_datingWinner: "",
  stage_6_datingBonus: false,
  stage_6_clear: false,
  stage_6_food_1: false,
  stage_6_food_1_clear: false,
  stage_6_food_2: false,
  stage_6_food_2_clear: false,
  stage_6_food_3: false,
  stage_6_food_3_clear: false,
  stage_6_photo_clear: false,
  stage_6_photo_clear_favor: false,
  //stage 7
  stage_7_everyone_can_goto: false,
  stage_7_default: false,
  stage_7_check1stPlace: false,
  stage_7_ghostRule: false,
  stage_7_goGhost: false,
  stage_7_ghostFinish: false,
  stage_7_ghostWinner: "",
  stage_7_food_1: false,
  stage_7_food_1_clear: false,
  stage_7_food_2: false,
  stage_7_food_2_clear: false,
  stage_7_ghost_over: false,
  stage_7_photo_clear: false,
  stage_7_photo_clear_favor: false,
  //other
  scoreResultEntered:false,
  result_uploading: false,
  //food
  douhua: false,
  beef: false,
  lautianlu: false,
  noodles: false,
  olympiafoods: false,
  pastry: false,
  pepperCake: false,
  plum: false,
  porkrice: false,
  starfruit: false,
  xiaolongbao: false
};
