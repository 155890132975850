export default {
  namespaced: true,
  state: {
    score: 0
  },
  mutations: {
    UPDATE_SCORE: function(state, target) {
      state.score = target;
    }
  },
  actions: {
    addScore: (context, data) => {
      context.commit("UPDATE_SCORE", data);
    }
  }
};
