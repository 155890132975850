<template>
  <div class="full-page overflow-hidden wellcome-bg position-relative app-content">
    <a @click="$router.replace('login')" class="position-fixed start-btn d-flex justify-content-center align-items-center">
      <strong>開始遊戲</strong>
    </a>
<!--    <button v-if="addshow" class="add-button" @click="add">Add to home screen</button>-->
  </div>
</template>
<style scoped>
.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

.start-btn {
  position: fixed;
  display: block;
  border-radius: 40px;
  width: 60vw;
  text-align: center;
  padding: 8px;
  background: #1ba7e9;
  color: #fff;
  font-weight: bold;
  border: 1px solid #fff;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bolder;
  font-size: 18px;
}
.start-btn:active {
  transform: translateX(-50%) scale(0.8);
}
</style>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  computed: {
    ...mapGetters("User", ["user"]),
    ...mapGetters("Team", ["team"]),
    ...mapGetters("Game", ["game"]),
    ...mapGetters(["latestPath"])
  },
  data() {
    return {
      addshow: false,
      deferredPrompt: null
    };
  },
  methods: {
    ...mapActions("User", ["resetAll"]),
    add() {
      if (!this.deferredPrompt) return;
      // Show the prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        this.deferredPrompt = null;
      });
    }
  },
  mounted() {
    /**
     *  1.輸入過正確遊戲序號之後，建立/加入隊伍前
     斷線後皆連回建立隊伍/加入隊伍畫面
     （確認是否有序號無法輸入第二次的問題）
     teamStart => teamStart
     2.進入選角階段時
     斷線後皆連回選角畫面
     （確認原本選的角色是否會因為斷線未清空而卡住無法選取）
     characters => characters
     3.選完角色，隊長按開始遊戲之後
     斷線後皆連回世界地圖
     （檢查是否會因為未收到隊長選關訊號而無法進關）
     * => homeMap
     4.跳舞中間斷線
     連回跳舞排行榜，等大家都到排行榜後，和其他人同步狀態
     dance => danceResult
     5.約會中間斷線
     連回約會小遊戲首頁（如目前設計）
     dating => dating
     6.打架中間斷線
     連回打架排行榜，等大家都到排行榜後，和其他人同步狀態
     fighting => fightingResult
     fightingByStand => fightingResult
     7.鬼抓人中間斷線
     連回鬼抓人遊戲頁面
     ghost => ghost
     8.北一女找靈異地點階段
     （確認有人找到地點的訊號/頁面是否可以補送）
     fgCompass => fgCompass
     */
    if (this.latestPath) {
      this.$router.replace(this.latestPath);
      return;
    }
    if (this.user?.id && this.team?.id && this.game?.id && this.character) {
      this.$router.replace("homeMap");
      return;
    }

    if (this.user?.id && this.team?.id) {
      this.$router.replace("teamCreate");
      return;
    }
    window.addEventListener("beforeinstallprompt", e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.addshow = true;
      // Update UI to notify the user they can add to home screen
    });
  }
};
</script>
