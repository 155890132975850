import { login, signup } from "../../utils/Api";
import { openTicket } from "../../utils/Api";
import { sendMessage } from "../../utils/WebSocket";
import router from "@/router";
import { getUserStatus } from "../../utils/Api";
import { getTimestamp } from "@/libs/time";

const RESET_LOGIN_TIME = true;
//eslint-disable-next-line
const SESSION_LENGTH = 36000;

const INTRO_SHOW_FLAG = {
  stage_map: true,
  food_mission: true
};

export default {
  namespaced: true,
  state: {
    isLogin: false,
    user: null,
    token: null,
    login_time: null,
    team_member: null,
    ticket: null,
    character: null,
    fd: null,
    fd_lock: false,
    flagSyncInterval: 15000,
    flagSyncTimer: 0,
    intro_show_flag: {
      ...{},
      ...INTRO_SHOW_FLAG
    },
    getUserStatusLock:false,
  },
  getters: {
    user: state => state.user,
    character: state => state.character,
    team_member: state => state.team_member,
    ticket: state => state.ticket,
    fd: state => state.fd,
    login_time: state => state.login_time,
    token: state => state.token,
    isLeader(state, pa, rootState) {
      return state?.user?.id && rootState?.Team?.team?.payload?.leader == state.user.id;
    },
    intro_show_flag: state => state.intro_show_flag,
    getUserStatusLock: state => state.getUserStatusLock,
  },
  mutations: {
    flagSyncTimer(state, flagSyncTimer) {
      state.flagSyncTimer = flagSyncTimer;
    },
    resetFlagSyncTimer(state) {
      clearInterval(state.flagSyncTimer);
      state.flagSyncTimer = 0;
    },
    RESET(state) {
      state.isLogin = false;
      state.user = null;
      state.token = null;
      state.login_time = null;
      state.team_member = null;
      state.ticket = null;
      state.character = null;
      state.fd = null;
      state.intro_show_flag = {
        ...{},
        ...INTRO_SHOW_FLAG
      };
    },
    setCharacter(state, target) {
      state.character = target;
    },
    setLoginState(state, target) {
      state.isLogin = target;
    },
    setFd(state, fd) {
      state.fd = fd;
    },
    setUserState(state, target) {
      state.user = target;
    },
    setTokenState(state, target) {
      if (target) {
        window.localStorage.setItem("authtoken", target);
      } else {
        window.localStorage.removeItem("authtoken");
      }
      state.token = target;
    },
    setLoginTime(state, reset) {
      if (reset === RESET_LOGIN_TIME) {
        state.login_time = null;
        return;
      }
      state.login_time = getTimestamp();
    },
    SET_TEAM_MEMBER(state, team_member) {
      state.team_member = team_member;
    },
    SET_TICKET(state, ticket) {
      state.ticket = ticket;
    },
    SET_FD_LOCK(state, fd_lock) {
      state.fd_lock = fd_lock;
    },
    SET_INTRO_HIDE(state, flag) {
      console.log("SET_INTRO_HIDE", flag);
      state.intro_show_flag[flag] = false;
    },
    SET_GET_USER_STATUS_LOCK(state,flag){
      state.getUserStatusLock = flag
    }
  },
  actions: {
    sendFlagSync({ state }) {
      if (!state.token) return;
      let message = {
        method: "SyncAllFlag",
        token: state.token
      };
      sendMessage(message);
    },
    flagSyncTimer({ commit, state }) {
      if (state.flagSyncTimer) return;
      let flagSyncTimer = setInterval(() => {
        let message = {
          method: "SyncAllFlag",
          token: state.token
        };
        sendMessage(message);
      }, state.flagSyncInterval);
      commit("flagSyncTimer", flagSyncTimer);
    },
    async openTicket({ commit }, ticket_code) {
      try {
        let response = await openTicket(ticket_code);
        if (response.status == 200) {
          let result = response.data;
          if (result.status == "success") {
            commit("SET_TICKET", result.data.ticket);
            commit("SET_TEAM_MEMBER", result.data.team_member);
          }
          return result;
        }
      } catch (e) {
        throw "網路連線失敗";
      }
    },
    doLogin: async ({ dispatch }, data) => {
      try {
        let response = await login(data);
        if (response.status == 200 && response?.data?.status === "success") {
          await dispatch("resetAll");
          await dispatch("User/setUserData", response, { root: true });
          let data = response?.data?.data
          if(data?.team_payload){
            await dispatch("Team/setPayload", response, { root: true });
          }
          if(data?.flags){
            await dispatch("Stage/flagsAllSync", {
              flags:response.data.flags
            }, { root: true });
          }
          if(data?.team){
            await dispatch("Team/teamSync",
              response,
              { root: true });
            let user_selected = data?.team_payload?.user_selected
            if(user_selected){
              await dispatch("Team/syncCharacterMap",
                {
                  data:{
                    team:data.team,
                    user_selected
                  }
                },
                { root: true });
            }
          }

          return true;
        }
      } catch (e) {
        dispatch("User/logout", null, { root: true });
        return false;
      }

      return false;
    },
    doSignUp: async ({ dispatch }, { form }) => {
      let response = await signup(form);
      if (response.status == 200 && response.data?.status == "success") {
        dispatch("resetAll");
        dispatch("User/setUserData", response, { root: true });
        return response.data;
      }

      return false;
    },
    SyncUserStatus: async ({ dispatch,commit,state },payload) => {
      if(state.user?.id){
        return;
      }

      if(state.getUserStatusLock) return;
      try {
        commit('SET_GET_USER_STATUS_LOCK',true)
        let response = await getUserStatus();
        if (response.status == 200 && response?.data?.status === "success") {
          await dispatch("User/setUserData", response, { root: true });
        }
        commit('SET_GET_USER_STATUS_LOCK',false)

      }
      catch (e) {
        commit('SET_GET_USER_STATUS_LOCK',false)

        let retry = payload?.retry || 0
        if(retry>=3){
          await dispatch("logout");
          return false;
        }
        setTimeout(function(){
           dispatch('SyncUserStatus',{retry:retry+1})
        },100)
        return false;
      }
      return false;
    },

    setUser: ({ commit }, payload) => {
      commit("setUserState", payload?.data?.user);
    },
    online({ dispatch }, data) {
      dispatch("setFd", data);
      dispatch("sendFlagSync");
    },
    setFd: ({ commit, state }, data = null) => {
      let token = state.token;
      if (!token) {
        return false;
      }
      let fd = data?.fd || data;
      if (fd == state.fd) return;
      commit("SET_FD_LOCK", true);
      let message = {
        method: "SyncFd",
        token,
        fd
      };
      //
      sendMessage(message);
    },
    syncFd: ({ commit, rootState,dispatch }, { data }) => {
      console.log("syncFd", data);
      const { fd, user, team_member, team, team_payload, flags, teams,resultMap,score_table } = data;
      commit("SET_FD_LOCK", false);
      commit("setFd", fd);
      commit("setLoginState", true);
      user && commit("setUserState", user);
      team_member && commit("SET_TEAM_MEMBER", team_member);
      if (team) {
        commit("Team/SET_TEAM", team, { root: true });
        commit("Team/SET_MEMBERS", team.member, { root: true });
        commit("Team/SET_TEAM_LEADER", team.payload.leader, { root: true });
        team_payload && commit("Team/SET_PAYLOAD", team_payload, { root: true });
        flags && commit("Stage/SYNC_FLAGS", flags, { root: true });
        commit("Game/SET_GAME", team.game, { root: true });
        commit("Game/SET_TEAMS", teams, { root: true });
        let game_locations = teams.map(row => {
          return {
            team_id: row.id,
            locations: row.locations
          };
        });
        commit("Game/SET_GAME_PAUSED", team_payload.game_paused, { root: true });
        commit("Game/SET_LOCATIONS", game_locations, { root: true });
        dispatch("Result/syncAll", resultMap, { root: true });
        dispatch("Score/syncAll", score_table, { root: true });
        let character_id = team_payload?.user_selected?.[user.id];
        commit("setCharacter", rootState.Character.roles?.[character_id]);

      }
    },
    loginExpire: context => {
      let timestamp = getTimestamp();
      return timestamp - context.state.login_time > 36000;
    },
    setUserData: ({ commit }, result) => {
      let user = result.data?.data?.user ?? result.data?.data;
      let token = result?.data?.token;

      console.log("result", result);

      commit("setLoginState", true);
      commit("setUserState", user);
      if (token) {
        commit("setTokenState", token);
      }

      let ticket = result.data?.data?.ticket;
      commit("SET_TICKET", ticket);
      commit("setLoginTime", RESET_LOGIN_TIME);
    },
    resetAll: ({ commit, dispatch }) => {
      localStorage.removeItem("vuex");
      commit("RESET");
      commit("UPDATE_LATEST_PATH", null, { root: true });
      commit("resetFlagSyncTimer");
      commit("Team/RESET", [], { root: true });
      commit("Stage/reset", [], { root: true });
      commit("Result/reset", [], { root: true });
      commit("Dating/reset", [], { root: true });
      commit("Fight/reset", [], { root: true });
      commit("Ghost/reset", [], { root: true });
      commit("Chat/reset", [], { root: true });
      commit("Dance/reset", [], { root: true });
      commit("Food/reset", [], { root: true });
      commit("Game/RESET", [], { root: true });
      commit("Score/RESET", [], { root: true });
      localStorage.removeItem("vuex");
      dispatch("websocketReconnect", null, { root: true });
    },
    logout: ({ dispatch }) => {
      dispatch("resetAll");
      // let message = {
      //   method: "Logout",
      //   token:state.token,
      // };
      //
      // sendMessage(message);
      router.replace("/login");
    },
    reset(context) {
      context.commit("RESET");
    },
    setCharacter({ commit, state }, { teamPayload, roles }) {
      let selectedCharacter = teamPayload?.user_selected[state.user.id.toString()];
      selectedCharacter = selectedCharacter || teamPayload?.user_selected[state.user.id];
      if (Number.isInteger(selectedCharacter)) {
        let character = roles[selectedCharacter];
        commit("setCharacter", character);
      } else {
        commit("setCharacter", null);
      }
    },
    unsetCharacter({ commit }) {
      commit("setCharacter", null);
    },
    setIntroHide({ commit }, { flag }) {
      commit("SET_INTRO_HIDE", flag);
    },
    setOnline({ state }) {
      let messageData = {
        method: "UserOnline",
        token: state.token
      };
      sendMessage(messageData);
    },
    setUserHint({ state }, { stage, step, hintIndex, newMsg }) {
      // console.log(this.data.hint[hintIndex])
      let messageData = {
        method: "StageMessage",
        token: state.token,
        stage,
        step,
        only: state.user?.id,
        hintIndex,
        newMsg
      };
      sendMessage(messageData);

      let score = -1;
      if (state.character?.name == "胖子" && hintIndex <= 1) {
        score = 0;
      }
      messageData = {
        method: "GetHint",
        token: state.token,
        stage,
        step,
        only: state.user?.id,
        score,
        hintIndex
      };
      sendMessage(messageData);
    }
  }
};
