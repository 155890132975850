import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import Team from "./modules/Team";
import User from "./modules/User";
import ScoreBar from "./modules/ScoreBar";

import createPersistedState from "vuex-persistedstate";
import Character from "./modules/Character";
import Stage from "./modules/Stage";
import Favor from "./modules/Favor";
import Fight from "./modules/Fight";
import Dating from "./modules/Dating";
import Ghost from "./modules/Ghost";
import Chat from "./modules/Chat";
import Result from "./modules/Result";
import Video from "./modules/Video";
import Food from "./modules/Food";
import Dance from "./modules/Dance";
import { sendMessage } from "../utils/WebSocket";
import Score from "./modules/Score";
import Game from "./modules/Game";
import { checkMotionPermission } from "@/utils/Motion";

let vuex_persisted = createPersistedState({
  key: "vuex",
  reducer(val) {
    if (val.User.token === null) {
      // val.user.token (your user token for example)
      return {};
    }
    return val;
  },
  paths: [
    "User.user",
    "User.character",
    "User.token",
    "User.login_time",
    "Team.team",
    "Team.ticket",
    "Team.members",
    "Team.character_map",
    "Team.payload",
    "Score.score_map",
    "Score.hint_map",
    "Score.game_begin_timestamp",
    "Game.teams",
    "Game.game",
    "Game.gamePaused",
    "Stage.stages",
    "Stage.flags",
    "Stage.local_flags",
    // "Stage.locations",
    //"Stage.stationStage",
    "Dating.talkingContent",
    "Dating.maxShakeCount",
    "Dating.shakeCount",
    "Dating.talkingList",
    "Fight.winners",
    "Fight.score_list",
    "latestRouter",
    'Ghost'
  ]
});
let plugins = [vuex_persisted];

export default new Vuex.Store({
  plugins,
  getters: {
    socket: state => state.socket,
    modal: state => state.modal,
    infoModal: state => state.infoModal,
    latestPath: state => state.latestPath,
    firstConnect: state => state.socket.firstConnect
  },
  state: {
    isPermission: false,
    socket: {
      isConnected: false,
      firstConnect: true,
      message: "",
      reconnectError: false,
      stage: null,
      heartBeatInterval: 30000,
      // 心跳定時器
      heartBeatTimer: 0
    },
    modal: {
      content: "",
      is_show: false
    },
    latestPath: null,
    latestRouter: null,
    infoModal: {
      title: "",
      content: "",
      is_show: false
    }
  },
  mutations: {
    DISABLE_FIRST_CONNECT(state) {
      state.socket.firstConnect = false;
    },
    UPDATE_LATEST_PATH(state, path) {
      if (path !== "/") {
        state.latestPath = path;
      }
    },
    UPDATE_LATEST_ROUTER(state, router) {
      state.latestRouter = router;
    },
    MODAL_CONTENT(state, content) {
      state.modal.content = content;
    },
    MODAL_IS_SHOW(state, show) {
      state.modal.is_show = show;
    },
    INFO_MODAL_CONTENT(state, { title, content }) {
      state.infoModal.title = title;
      state.infoModal.content = content;
    },
    INFO_MODAL_IS_SHOW(state, show) {
      state.infoModal.is_show = show;
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
      state.socket.heartBeatTimer = setInterval(() => {
        let message = {
          method: "HeartBeat",
          data: new Date()
        };
        state.socket.isConnected && sendMessage(message);
      }, state.socket.heartBeatInterval);
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
      clearInterval(state.socket.heartBeatTimer);
      state.socket.heartBeatTimer = 0;
    },
    SOCKET_ONERROR(state, event) {
      console.error("error", state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      console.log("SOCKET_ONMESSAGE", message);
      state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info("reconnect", count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    setIsPermission(state, status) {
      state.isPermission = status;
    }
  },
  actions: {
    disableFirstConnect({ commit }) {
      commit("DISABLE_FIRST_CONNECT");
    },
    doCheckMotionPermission({ commit, state }) {
      if (state.isPermission) return;
      checkMotionPermission().then(isPermission => {
        commit("setIsPermission", isPermission);
      });
    },
    sendMessage: function(context, message) {
      Vue.prototype.$socket.send(message);
    },
    websocketStart: () => {
      // this.$connect();
      // // Connect to an alternative websocket URL and Options e.g.
      // this.$connect("ws://localhost:5200/", { format: "json" });
      // do stuff with WebSockets
    },
    websocketReconnect: ({ commit }) => {
      // console.log(Vue.prototype.$socket);
      // WebSocket.close();
      Vue.prototype.$socket.close();
      commit("SOCKET_ONCLOSE");
    },
    setModal(context, payload) {
      let content = "";
      let show = false;
      if (payload?.data) {
        content = payload?.data.content;
        show = payload?.data.show;
      } else {
        if (payload?.content) {
          content = payload?.content;
          show = payload?.show;
        } else {
          content = payload;
          show = true;
        }
      }
      if (content) {
        context.commit("MODAL_CONTENT", content);
        context.commit("MODAL_IS_SHOW", true);
        return;
      }
      context.commit("MODAL_IS_SHOW", show);
    },
    setInfoModal(context, { title, content, show }) {
      if (content) {
        context.commit("INFO_MODAL_CONTENT", { title, content });
        context.commit("INFO_MODAL_IS_SHOW", true);
        return;
      }
      context.commit("INFO_MODAL_IS_SHOW", show);
    },
    UpdateLatestPath({ commit }, { to }) {
      const not_log = [
        'checkList',
        'test',
        'logout',
        'login',
      ];
      if(not_log.includes(to.path)) return;
      commit("UPDATE_LATEST_PATH", to.path);
      // commit('UPDATE_LATEST_ROUTER',{to,from});
    }
  },
  modules: {
    Team,
    User,
    ScoreBar,
    Character,
    Stage,
    Favor,
    Fight,
    Dating,
    Ghost,
    Chat,
    Result,
    Video,
    Food,
    Dance,
    Score,
    Game
  }
});
