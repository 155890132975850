import { sendMessage } from "../../utils/WebSocket";
import router from "../../router";
import Vue from "vue";
import { getTimestampM } from "@/libs/time";

export default {
  namespaced: true,
  state: {
    ready_list: {},
    role: null,
    score_list: {},
    times: 0,
    start_timestamp: null
  },
  getters: {
    players: state => Object.values(state.ready_list).filter(d => d.role == "player"),
    dancers: state => Object.values(state.ready_list).filter(d => d.role == "dancer"),
    ready_list: state => Object.values(state.ready_list),
    role: state => state.role,
    score_list: state => state.score_list,
    times: state => state.times,
    start_timestamp: state => state.start_timestamp
  },
  mutations: {
    set_start_timestamp(state, timestamp) {
      state.start_timestamp = timestamp;
    },
    add_times(state) {
      state.time = state.time + 1;
    },
    reset(state) {
      state.ready_list = {};
      state.role = null;
      state.score_list = {};
    },
    set_score_list(state, { user, character, score }) {
      let row = state.score_list?.[user.id];
      if (row) {
        if (row.score > score) return;
      }

      state.score_list[user.id] = {
        user,
        character,
        score
      };
    },
    set_role(state, role) {
      if (state.role == role) {
        state.role = null;
        return;
      }
      state.role = role;
    },
    set_ready_list(state, { user, role, character }) {
      if (state.ready_list[user.id]?.role == role) {
        Vue.delete(state.ready_list, user.id);

        return;
      }
      let temp = {};
      temp[user.id] = {
        user,
        role,
        character
      };
      state.ready_list = { ...state.ready_list, ...temp };
    }
  },
  actions: {
    addTimes({ commit }) {
      commit("add_times");
    },
    setReadyList({ commit }, { data: { user, role, character } }) {
      commit("set_ready_list", { user, role, character });
    },
    danceChoseRole({ commit, rootState }, { character, role }) {
      commit("set_role", role);
      const data = {
        method: "DanceStandBy",
        token: rootState.User.token,
        character,
        role
      };
      sendMessage(data);
    },
    start({ rootState }) {
      const data = {
        method: "DanceStart",
        token: rootState.User.token
      };
      sendMessage(data);
    },
    resetStartTimestamp({ commit }) {
      commit("set_start_timestamp", null);
    },
    gotoPage({ state, commit }) {
      console.log(state.role);
      if (!state.start_timestamp) {
        commit("set_start_timestamp", getTimestampM());
      }

      if (state.role == "player") {
        router.replace("danceVideo");
        return;
      }
      if (state.role == "dancer") {
        router.replace("dance");

        return;
      }
    }
  }
};
