import { getGameScore, getGameData } from "@/utils/Api";
import { sendMessage } from "@/utils/WebSocket";
import router from "@/router";

let initialState = () => ({
  game: null,
  gamePaused: false,
  teams: [],
  payload: null,
  score_of_teams: [],
  locations: [],
  game_data: []
});
export default {
  namespaced: true,
  state: initialState,
  getters: {
    game: state => state.game,
    gamePaused: state => state.gamePaused,
    teams: state => state.teams,
    score_of_teams: state => state.score_of_teams,
    member_locations: state => state.locations,
    game_data: state => state.game_data,
    teams_user_selected: state => {
      let result = {};
      state.teams.forEach(team => {
        result[team.id] = team?.team_payload?.user_selected;
      });
      return result;
    },
    users: state => {
      let result = {};
      state.teams.forEach(team => {
        team.member.forEach(member => {
          let user = member.user;
          if (user.id > 0) result[user.id] = user;
        });
      });
      return result;
    }
  },
  mutations: {
    RESET: state => {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    SET_GAME: function(state, game) {
      state.game = game;
    },
    SET_TEAMS: function(state, teams) {
      state.teams = teams;
    },
    SET_PAYLOAD: function(state, payload) {
      state.payload = payload;
    },
    JOIN_GAME: function(state, team) {
      state.teams.push(team);
    },
    LEAVE_GAME: function(state, team) {
      state.teams = state.teams.filter(d => d.id !== team.id);
    },
    SET_SCORE_OF_TEAMS: (state, result) => {
      state.score_of_teams = [...[], ...result];
    },
    SET_GAME_PAUSED: (state, gamePaused) => {
      state.gamePaused = gamePaused;
    },
    SET_LOCATIONS: (state, game_locations) => {
      let temp = game_locations.map(row => {
        return Object.keys(row.locations).map(user_id => {
          return {
            team_id: row.team_id,
            user_id: parseInt(user_id),
            location: JSON.parse(row.locations[user_id])
          };
        });
      });
      state.locations = [...[], ...temp];
    },
    SET_GAME_DATA: (state, game_data) => {
      state.game_data = game_data;
    }
  },
  actions: {
    setTeams: (context, teams) => {
      context.commit("SET_TEAMS", teams);
    },
    joinGame: (context, team) => {
      context.commit("JOIN_GAME", team);
    },
    setGame({ commit }, { data }) {
      commit("SET_GAME", data.game);
    },
    gameSync({ commit }, { teams }) {
      commit("SET_TEAMS", teams);
    },
    gameLeave(context) {
      context.commit("RESET");
    },
    gameStart: () => {
      router.replace("/characters");
    },
    setGamePayload({ commit }, { payload }) {
      commit("SET_PAYLOAD", payload);
    },
    async scoreOfTeams({ commit }, code) {
      commit("SET_SCORE_OF_TEAMS", []);
      try {
        let response = await getGameScore(code);
        if (response.status == 200) {
          let result = response.data;

          commit("SET_SCORE_OF_TEAMS", result);
          return result;
        }
      } catch (e) {
        return e;
      }
    },
    async gameDataResult({ commit }, game_id) {
      try {
        let resp = await getGameData(game_id);
        let team_rank = resp.data.sort((a, b) => b.total_score - a.total_score); // 大到小
        commit("SET_GAME_DATA", team_rank);
        return team_rank;
      } catch (e) {
        console.log(e);
      }
    },
    setGamePaused({ commit }, { game_paused }) {
      commit("SET_GAME_PAUSED", game_paused);
    },
    setLocations({ commit }, result) {
      commit("SET_LOCATIONS", result?.data?.game_locations);
    },
    toggleGamePause(context, isPaused) {
      context.commit("SET_GAME_PAUSED", isPaused);
      const data = {
        method: "GamePause",
        token: context.rootGetters["User/token"],
        game_id: context.rootGetters["Game/game"].id,
        game_paused: isPaused
      };
      sendMessage(data);
    },
    gameLogCreate(context, { label, payload }) {
      const data = {
        method: "GameLogCreate",
        token: context.rootGetters["User/token"],
        game_id: context.rootGetters["Game/game"].id,
        label,
        payload
      };
      sendMessage(data);
    }
  }
};
