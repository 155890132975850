import "bootstrap";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vue2TouchEvents from "vue2-touch-events";
import VueNativeSock from "vue-native-websocket";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueScrollTo from "vue-scrollto";
import VueCompositionApi from "@vue/composition-api";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.use(VueScrollTo);
Vue.use(VueCompositionApi);
Vue.use(VueReCaptcha, {
  siteKey: "6Le_QqYZAAAAAGRkzFNDp-zU8j4bav3Qd6Ey4N0K",
  loaderOptions: {
    autoHideBadge: true
  }
});

Vue.use(Vue2TouchEvents);
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_PATH, {
  store: store,
  format: "json",
  // connectManually: true,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  // reconnectionAttempts: 100, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 100 // (Number) how long to initially wait before attempting a new (1000)
});

Vue.config.productionTip = process.env?.VUE_APP_PRODUCTION＿TIP;
if(process.env.NODE_ENV!=='test'){


  Sentry.init({
    Vue,
    dsn: "https://cbbee7b9aa484ec284cc93e58c44381b@o552530.ingest.sentry.io/5678447",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


new Vue({
  router,
  store,
  LineIt: window.LineIt,
  fbAsyncInit: window.fbAsyncInit,
  render: h => h(App)
}).$mount("#app");
