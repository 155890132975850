export default {
  namespaced: true,
  state: {
    roles: [
      {
        name: "胖子",
        intro:
          "每個人周遭都會有個胖子，我們的胖子一直是我們之中最知識淵博最沉穩的。胖子對每件事情都很認真，連喜歡小桃這件事也是",
        ability: "知識淵博－使用提示功能時較不容易被扣好感值",
        picture: "avatar2@2x.jpg",
        figure: "p3.png",
        card: "dating-card2.png",
        cksWin: "cks-win2.jpg"
      },
      {
        name: "花輪",
        intro: "帥氣愛打扮的富二代，外表看似花心但其實很專情體貼，情商高，雖然有點自大但心地善良，對朋友很慷慨",
        ability: "有錢人比較會－得到的好感值就是比別人高一點",
        picture: "avatar3@2x.jpg",
        figure: "p2.png",
        card: "dating-card3.png",
        cksWin: "cks-win3.jpg"
      },
      {
        name: "小希",
        intro: "小桃最好的朋友，外表可愛溫柔但其實個性強韌，慢熱臉盲又怕生的她，似乎對小彥有些特別的感覺",
        ability: "閨蜜的祝福－得到好感值後，可以選一個人幫他增加好感值",
        picture: "avatar4@2x.jpg",
        figure: "p1.png",
        card: "dating-card4.png",
        cksWin: "cks-win4.jpg"
      },
      {
        name: "赤木",
        intro:
          "總穿著湘北隊的球衣，配上197的身高，因此大家叫他赤木，雖然本人覺得自己像流川楓。熱愛籃球的運動高手，各種體能活動都難不倒他，不過頭腦簡單的程度似乎也無人可及",
        ability: "燃起來了－體能任務獲勝後，增加的好感值較多",
        picture: "avatar5@2x.jpg",
        figure: "p5.png",
        card: "dating-card5.png",
        cksWin: "cks-win5.jpg"
      },
      {
        name: "白爛",
        intro:
          "如果讓人翻白眼也算一種能力的話，白爛的戰鬥力起碼有一萬以上！愛亂講話的他平常白痴又智障，但卻是我們不可或缺的開心果",
        ability: "聽你在屁－白爛增加好感值時，其他人受到心靈衝擊、好感值下降\n",
        picture: "avatar6@2x.jpg",
        figure: "p4.png",
        card: "dating-card6.png",
        cksWin: "cks-win6.jpg"
      },
      {
        name: "春哥",
        intro:
          "聰明低調有條理，是我們這群人的智力擔當。帶點文青氣息又是吉他社教學的她，風靡了校內無數女孩，但她似乎還需要一點時間來探索、認識自己",
        ability: "近水樓台－有比較高的初始好感值",
        picture: "avatar7@2x.jpg",
        figure: "p6.png",
        card: "dating-card7.png",
        cksWin: "cks-win7.jpg"
      },
      {
        name: "小彥",
        intro:
          "堅持、認真、可愛、陽光，笑起來很好看的大男孩，個性溫和知道進退，也許不是最聰明的但往往是人緣最好的，不知不覺就成為小團體的核心",
        ability: "",
        picture: "avatar0@2x.jpg",
        figure: "p8.png",
        lock: true
      },
      {
        name: "小桃",
        intro:
          "親切大方、笑容甜美，綁著青春無敵的馬尾，就是這樣的她，讓高中時期的我們一起喜歡上同個女孩，每個人都使盡渾身解數來吸引她的目光<br><br>窈窕的身材裡藏著一個愛吃的靈魂，在台北市的各個角落，都有她留下的美食回憶，不過她的最愛還是永不離手的小杯楊桃汁",
        ability: "",
        picture: "avatar1@2x.jpg",
        figure: "p7.png",
        lock: true
      }
    ]
  },
  getters: {
    roles: state => state.roles,
    getRoleByName: state => name => {
      return state.roles.find(d => d.name == name);
    }
  },
  actions: {
    characterWatch(context, payload) {
      context.commit("Team/SET_PAYLOAD", payload?.data?.team_payload, { root: true });
    },
    characterSelected({ commit, dispatch, state }, payload) {
      let teamPayload = payload?.data?.team_payload;
      commit("Team/SET_PAYLOAD", teamPayload, { root: true });
      dispatch(
        "User/setCharacter",
        {
          teamPayload,
          roles: state.roles
        },
        { root: true }
      );
    }
  }
};
