import axios from "axios";

axios.defaults.withCredentials = true;
const SanctumInit = false;
const sanctumCookie = function(config) {
  // Do something before request is sent
  if (SanctumInit === true || config.url.match("/sanctum/csrf-cookie")) {
    return config;
  } else {
    axios.get(process.env.VUE_APP_PATH+"sanctum/csrf-cookie");
  }

  return config;
};

const appendToken = config => {
  let token = window.localStorage.getItem("authtoken");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const api_path = process.env.VUE_APP_API_PATH || "http://localhost:8080/api";
const userRequest = axios.create({
  baseURL: `${api_path}/user/`
});
userRequest.interceptors.request.use(sanctumCookie);
userRequest.interceptors.request.use(appendToken);

const ticketRequest = axios.create({
  baseURL: `${api_path}/ticket/`
});
ticketRequest.interceptors.request.use(sanctumCookie);
ticketRequest.interceptors.request.use(appendToken);
const teamRequest = axios.create({
  baseURL: `${api_path}/team/`
});
teamRequest.interceptors.request.use(sanctumCookie);
teamRequest.interceptors.request.use(appendToken);

const gameRequest = axios.create({
  baseURL: `${api_path}/game/`
});
gameRequest.interceptors.request.use(sanctumCookie);
gameRequest.interceptors.request.use(appendToken);

export const login = data => userRequest.post("/login", data);
export const getUserStatus = data => userRequest.post("/status", data);
export const checkEmail = data => userRequest.post("/emailcheck", data);
export const signup = data => userRequest.post("/signup", data);
export const avatarUpload = (data, config) => userRequest.post("/avatar/upload", data, config);
export const openTicket = code => ticketRequest.get("/" + code);
export const teamPost = team => teamRequest.post("/", team);
export const teamJoin = code => teamRequest.get("/join/" + code);
export const teamGet = code => teamRequest.get("/" + code);
export const teamPhotoUpload = (data, config) => teamRequest.post("/result/photoupload", data, config);
export const getGameScore = code => gameRequest.get(`/${code}`);
export const getGameData = game_id => gameRequest.get(`/${game_id}/result`);

// export default {
//   userRequest
// };
