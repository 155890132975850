import { sendMessage } from "../../utils/WebSocket";

const character_rate = function(characterName, isMoveStage = false) {
  if (characterName == "花輪") return 1.1;
  if (characterName == "赤木" && isMoveStage) return 1.5;
  return 1;
};

function getTimestamp() {
  const dateTime = Date.now();
  const timestamp = Math.floor(dateTime / 1000);
  return timestamp;
}

export default {
  namespaced: true,
  state: {
    score_map: {},
    game_begin_timestamp: 0,
    hint_map: {}
  },
  getters: {
    score_map: state => state.score_map,
    character_rate: () => (character, moveStage) => {
      return character_rate(character, moveStage);
    },
    hint_rate: state => (key, characterName) => {
      if (!key) return 1;
      let times = state.hint_map?.[key] ?? 0;
      if (characterName == "胖子") times = times - 1;
      if (times < 0) times = 0;
      switch (times) {
        case 0:
          return 1;
        case 1:
          return 0.9;
        case 2:
          return 0.7;
        case 3:
          return 0.7;
        case 4:
          return 0.5;
        case 5:
          return 0.5;
        case 6:
          return 0.2;
        default:
          return 0.2;
      }
    },
    time_rate: () => {
      return 1;
      // const timestamp = getTimestamp();
      // let gap = timestamp - parseInt(state.game_begin_timestamp);
      //
      // if (gap <= 1800) return 1;
      // if (gap <= 3600) return 0.9;
      // return 0.8;
    },
    hintCount: state => key => {
      return state.hint_map?.[key] || 0;
    }
  },
  mutations: {
    RESET: state => {
      state.score_map = {};
      state.game_begin_timestamp = 0;
      state.hint_map = {};
    },
    SYNC_ALL(state,score_table){
      for(let user_id in score_table){
        state.score_map[user_id] = score_table[user_id];
      }
    },
    SET_SCORE(state, { user_id, score, user_score }) {
      // let old_score = state.score_map?.[user_id] || 0;
      if (user_score) {
        state.score_map[user_id] = user_score;
      }
      // } else {
      //   let newScore = old_score + score;
      //   newScore = newScore ? newScore : 0;
      //   state.score_map[user_id] = newScore;
      // }
      console.log("SET_SCORE ", state.score_map);
    },
    hint_map_add(state, key) {
      let value = state.hint_map?.[key] ?? 0;
      let temp = {};
      temp[key] = value + 1;
      state.hint_map = { ...state.hint_map, ...temp };
    },
    set_game_begin_timestamp(state) {
      state.game_begin_timestamp = getTimestamp();
    }
  },
  actions: {
    begin({ commit }) {
      commit("set_game_begin_timestamp");
    },
    hintMapAdd({ commit }, key) {
      commit("hint_map_add", key);
    },
    addScoreByRate(
      { dispatch, getters, rootGetters },
      { score, characterName, isMoveStage, hint_key, user_id, no_time, no_hint }
    ) {
      user_id = user_id || rootGetters["User/user"].id;
      score = score * 100;
      characterName = characterName?.name || characterName;
      if (!no_time) {
        score = score * getters.time_rate;
      }
      if (!no_hint) {
        score = score * getters.hint_rate(hint_key, characterName);
      }
      score = score * getters.character_rate(characterName, isMoveStage);
      dispatch("AddUserScore", { score, user_id });
    },
    AddUserScore({ rootGetters }, { score, user_id, lock_key }) {
      user_id = user_id || rootGetters["User/user"].id;
      const data = {
        method: "AddUserScore",
        token: rootGetters["User/token"],
        user_id,
        score,
        lock_key
      };
      sendMessage(data);
    },
    setUserScoreWebsocket({ commit }, { data: { user_id, score, user_score } }) {
      commit("SET_SCORE", { user_id, score, user_score });
    },
    syncUsersScoreWebsocket({ commit }, { users }) {
      users.forEach(user => {
        commit("SET_SCORE", { user_id: user.user_id, user_score: user.score });
      });
    },
    syncAll({commit},score_table){
      commit('SYNC_ALL',score_table)
    }
  }
};
