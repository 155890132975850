<template>
  <div>
    <div v-if="!isOnline" class="position-absolute connection">
      <img src="img/icons/connection.png" alt="" />
      <!--      <button @click="online">重新連線</button>-->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ShowWebsocketConnection",
  computed: {
    isOnline() {
      let isConnected = this.$store.state.socket?.isConnected;
      return !!isConnected;
      // console.log("isConnected", isConnected);
      // if (typeof isConnected === "undefined" || isConnected) return true;
      // return false;
    }
  },
  methods: {
    ...mapActions("User", ["setOnline"]),
    online() {
      if (this.$store.state.socket?.isConnected) {
        this.setOnline();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connection {
  z-index: 999;
  width: 150px;
  height: 150px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px;

  img {
    width: 100%;
  }

  -webkit-animation: twinkling 1.5s infinite ease-in-out;

  .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@-webkit-keyframes twinkling {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes twinkling {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
