import { teamPost } from "../../utils/Api";
import { sendMessage } from "../../utils/WebSocket";
import router from "../../router";

const initialState = () => {
  return {
    name: "隊伍名稱",
    team: null,
    members: [],
    leader_id: null,
    payload: {},
    character_map: {},
    score_map: {},
    winner: ""
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    character_map: state => state.character_map,
    score_map: state => state.score_map,
    members: state => {
      return state.members;
    },
    leader: state => {
      let result = state.members.filter(member => {
        return member.user_id == state?.team?.payload?.leader;
      });
      return result && result.length > 0 ? result?.[0] : [];
    },
    team: state => state.team,
    team_payload: state => state.payload,
    isLeader: (state, getters, rootState) => {
      return state?.team?.payload?.leader == rootState?.User?.user?.id;
    },
    paused_count: state => {
      return state?.payload?.game_paused_count;
    },
    paused_at: state => {
      return state?.payload?.game_paused_at;
    },
    winner: state => {
      return state?.winner;
    }
  },
  mutations: {
    RESET: state => {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    SET_SCORE(state, { user_id, score, user_score }) {
      let old_score = state.score_map?.[user_id] || 0;
      if (user_score) {
        state.score_map[user_id] = user_score;
      } else {
        let newScore = old_score + score;
        newScore = newScore ? newScore : 0;
        state.score_map[user_id] = newScore;
      }
    },
    SET_PAYLOAD(state, target) {
      state.payload = target;
    },
    MERGE_PAYLOAD(state, target) {
      let payload = Object.assign(state.payload, target);
      state.payload = payload;
    },
    ADD_MEMBER(state, target) {
      state.member.push(target);
    },
    REMOVE_MEMBER(state, member) {
      state.members = state.members.filter(d => d.id !== member.id);
    },
    SET_TEAM(state, team) {
      state.team = team;
    },
    SET_TEAM_LEADER(state, leader_id) {
      state.leader_id = leader_id;
    },
    SET_MEMBERS(state, members) {
      state.members = members;
    },
    SET_CHARACTER_MAP(state, character_map) {
      state.character_map = character_map;
    },
    SET_WINNER(state, winner) {
      state.winner = winner;
    }
  },
  actions: {
    addMember(context, member) {
      context.commit("ADD_MEMBER", member);
    },
    removeMember(context, member) {
      context.commit("REMOVE_MEMBER", member);
    },
    setTeam(context, team) {
      context.commit("SET_TEAM", team);
    },
    setWinner(context, winner) {
      context.commit("SET_WINNER", winner);
    },
    async teamCreateOrUpdate({ commit }, team) {
      try {
        let response = await teamPost(team);
        if (response.status == 200) {
          let result = response.data;
          if (result.status == "success") {
            commit("SET_TEAM_LEADER", result?.data?.team?.payload?.leader);
            commit("SET_TEAM", result?.data?.team);
            commit("SET_MEMBERS", result?.data?.team?.member);

            return result;
          }
        }
      } catch (e) {
        return e;
      }
    },
    teamSync({ commit }, payload) {
      let team = payload?.data?.team;
      let members = team?.member || payload?.members;
      let team_payload = payload?.data?.team_payload;
      commit("SET_TEAM", team);
      commit("SET_MEMBERS", members);
      commit("SET_PAYLOAD", team_payload);
    },
    teamJoin(context, code) {
      const data = {
        method: "TeamJoin",
        token: context.rootGetters["User/token"],
        code: code
      };
      sendMessage(data);
    },
    teamMove() {
      router.replace("/teamCreate");
    },
    teamLeave({commit,dispatch}) {
      commit("RESET");
      commit("Game/RESET", null, { root: true });
      commit("UPDATE_LATEST_PATH", null, { root: true });
      commit("User/resetFlagSyncTimer", null, { root: true });
      commit("Stage/reset", [], { root: true });
      commit("Result/reset", [], { root: true });
      commit("Dating/reset", [], { root: true });
      commit("Fight/reset", [], { root: true });
      commit("Ghost/reset", [], { root: true });
      commit("Chat/reset", [], { root: true });
      commit("Dance/reset", [], { root: true });
      commit("Food/reset", [], { root: true });
      commit("Score/RESET", [], { root: true });
      localStorage.removeItem("vuex");
      router.replace("/teamStart");
      dispatch("websocketReconnect", null, { root: true });

    },
    TeamMemberReady({ commit }, { data }) {
      let team_payload = { ...data.team_payload, ready_list: data.ready_list };
      commit("SET_PAYLOAD", team_payload);
    },
    reset(context) {
      context.commit("RESET");
    },
    gameStart: () => {
      router.replace("/characters");
    },
    dancePlay({ commit }, { data: { team_payload } }) {
      commit("SET_PAYLOAD", team_payload);
    },
    dancePlayEnd({ commit }, { data: { team_payload } }) {
      commit("SET_PAYLOAD", team_payload);
      // router.replace("/danceResult");
    },
    danceScore({ commit }, { data: { team_payload } }) {
      commit("SET_PAYLOAD", team_payload);
    },
    syncCharacterMap({ commit, rootState }, { data: { user_selected } }) {
      let roles = rootState.Character.roles;
      let map = Object.keys(user_selected).map(user_id => {
        let character = roles[user_selected[user_id]];
        return {
          user_id,
          character
        };
      });
      commit("SET_CHARACTER_MAP", map);
    },
    appendPayload({ commit }, content) {
      commit("MERGE_PAYLOAD", content.data);
    },
    setPayload({ commit }, content) {
      commit("SET_PAYLOAD", content.data.team_payload);
    },
    setUserScoreWebsocket({ commit }, { data: { user_id, score, user_score } }) {
      commit("SET_SCORE", { user_id, score, user_score });
    },
    syncUsersScoreWebsocket({ commit }, { users }) {
      users.forEach(user => {
        commit("SET_SCORE", { user_id: user.user_id, score: user.score });
      });
    },
    setTeamMemberLocation({ rootGetters }, { longitude, latitude }) {
      const data = {
        method: "TeamMemberLocation",
        token: rootGetters["User/token"],
        longitude,
        latitude
      };
      sendMessage(data);
    },
    getTeamPayload({ rootGetters }) {
      if (rootGetters?.User?.token) {
        const data = {
          method: "GetTeamPayload",
          token: rootGetters["User/token"]
        };
        sendMessage(data);
      }
    },
    async clearCharacterSelect({ commit,dispatch }, content){
      await commit("SET_PAYLOAD", content.data.team_payload);

      await dispatch(
        "User/unsetCharacter",
        null,
        { root: true }
      );
      router.replace({ name:"teamCreate", params: { force: true }});
    },
    async characterSelectDone({dispatch}, { users,data,teams }){
      await dispatch('Score/syncUsersScoreWebsocket',{users},{root:true})
      await dispatch('Score/begin',null,{root:true})
      await dispatch('Team/syncCharacterMap', { data },{root:true})
      await dispatch('Game/gameSync', { teams },{root:true})
      await router.replace('openScene')


    },
    async fightingByStand ({ dispatch,rootState },payload) {
      await dispatch('setPayload',payload)
      let team_payload = payload.data.team_payload


      const incrementGameTimes = team_payload?.fighting?.incrementGameTimes || 1
      await dispatch('Fight/incrementGameTimes',{
          incrementGameTimes
      },{root:true});
      await dispatch('Fight/setDefault',{
        payload
      },{root:true});

      const fight_user = [
        team_payload?.fighting?.role.player1.character?.name,
        team_payload?.fighting?.role.player2.character?.name
      ]
      if(fight_user.includes(rootState.User.character.name)){
        await router.replace('fighting')
      } else{
        await router.replace('fightingByStand')
      }

    }
  }
};
