<template>
  <div class="app-modal" v-if="modal.is_show">
    {{ content }}
    <div class="pt-3">
      <button id="modal-confirm" class="btn btn-outline-primary btn-block" @click="closeModal">確定</button>
    </div>
  </div>
</template>

<script>
import ModalMixin from "../../Mixins/ModalMixin";

export default {
  name: "modal",
  mixins: [ModalMixin],
  computed: {
    content() {
      return this.modal?.content || "";
    }
  }
};
</script>

<style scoped></style>
