import { SDK_NAME } from "@sentry/browser";

export default {
  namespaced: true,
  state: {
    fake_full_screen: false,
    path: "https://us-east-1.linodeobjects.com/realescape-test/",
    vimeo_map: {
      nanyang: "S01",
      end: "https://vimeo.com/477094753/da52d76684",
      open: "S00",
      fg0: "S03",
      S00: "https://vimeo.com/431824954/522c64d067",
      S01: "https://vimeo.com/431825423/66f5dc185e",
      "S02-1": "https://vimeo.com/431826115/247c62af56",
      "S02-2": "https://vimeo.com/431826390/a7d3cee86b",
      "Dance-short": "https://vimeo.com/432853037/a0165e87ef",
      S03: "https://vimeo.com/431826742/6868b2b9ed",
      S03A: "https://vimeo.com/431827450/fb7fc85b76",
      S03B: "https://vimeo.com/431827685/91245e9432",
      S03C: "https://vimeo.com/431827995/20588d322d",
      S04: "https://vimeo.com/431828169/c99306e0f4",
      S05: "https://vimeo.com/431828810/a8083f25ef",
      S06: "https://vimeo.com/431829489/8787432194",
      S07: "https://vimeo.com/431830258/bc39e4bcaa",
      S08: "https://vimeo.com/431860563/504c0ea93c",
      S09: "https://vimeo.com/431830823/d1a66c4f3a",
      S10: "https://vimeo.com/431831030/225fd43057",
      S11: "https://vimeo.com/431831208/6544ba61f0",
      S12: "https://vimeo.com/431831410/dbc3f4c569",
      S13: "https://vimeo.com/431831548/55c04ddff1",
      S14: "https://vimeo.com/431831736/e3475d61f5",
      S15: "https://vimeo.com/431831984/49c785c8ca",
      danceDemo: "https://vimeo.com/478943380/0aec8cec27",
      datingDemo: "https://vimeo.com/478976083/830bab83c2",
      fightDemo: "https://vimeo.com/478908280/a2aea92708",
      ghostDemo: "https://vimeo.com/479032389/2b0df9e98d",
      gameIntro: "https://vimeo.com/485103243/4de97ee6fe"
    },
    map: {
      open: "https://file.badideasstudio.com/mc/open.mp4",
      end: "/mc/end.mp4",
      endScene: "/mc/endScene.mp4",
      openScene: "/mc/openScene.mp4",
      danceShort: "mc/dance-short.mp4",
      cshVideo: "/mc/S01-F-200617-ProRes422-4K-H265.mp4",
      fg1: "/mc/S03-F-200617-ProRes422-4K-H265.mp4",
      fg2: "/mc/S03A-F-200617-ProRes422-4K-H265.mp4",
      fg3: "/mc/S03B-F-200617-ProRes422-4K-H265.mp4",
      fg4: "/mc/S03C-F-200617-ProRes422-4K-H265.mp4",
      nanyang: "/mc/S01-F-200617-ProRes422-4K-H265.mp4",
      cks: "/mc/S02-F-200617-ProRes422-4K-H265.mp4",
      park228: "/mc/S06-F-200525.mp4",
      ximen: "/mc/S05-F-200525.mp4",
      "turn-page": "https://file.badideasstudio.com/mc/turn-page.mp4"
    },
    winnerVideo: {
      胖子: "S09",
      花輪: "S10",
      白爛: "S11",
      赤木: "S12",
      春哥: "S13",
      小希: "S14",
      小彥: "S15"
    },
    scenesName: {
      S00: "同學會開場",
      S01: "高一上",
      "S02-1": "高一下之一",
      "S02-2": "高一下之二",
      S03: "高二上之一",
      S03A: "高二上之二",
      S03B: "高二上之三",
      S03C: "高二上之四",
      S04: "高二下",
      S05: "高三上",
      S06: "升大學暑假",
      S07: "升大學暑假",
      S08: "同學會結尾",
      S09: "胖子的結局",
      S10: "花輪的結局",
      S11: "白爛的結局",
      S12: "赤木的結局",
      S13: "春哥的結局",
      S14: "小希的結局",
      S15: "隱藏結局"
    }
  },
  getters: {
    fake_full_screen: state => state.fake_full_screen,
    getVimeoKey: state => Object.keys(state.vimeo_map),
    getVideoPath: state => key => {
      let sub_path = state.map[key];
      if (sub_path.startsWith("http")) {
        return sub_path;
      }
      return state.path + sub_path;
    },
    getWinnerVideoPath: state => path => state.winnerVideo[path],
    getVimeoId: state => name => {
      let url = state.vimeo_map?.[name];
      if (!url) return false;
      if (url?.includes?.("http")) {
        return url;
      }
      return state.vimeo_map?.[url];
    },
    getScenesName: state => state.scenesName
  },
  mutations: {
    fake_full_screen(state, status) {
      state.fake_full_screen = status;
    }
  },
  actions: {
    fakeFullScreen({ commit }, { status }) {
      commit("fake_full_screen", status);

      // if(status && elem){
      //   if (elem.requestFullscreen) {
      //     elem.requestFullscreen();
      //   } else if (elem.msRequestFullscreen) {
      //     elem.msRequestFullscreen();
      //   } else if (elem.mozRequestFullScreen) {
      //     elem.mozRequestFullScreen();
      //   } else if (elem.webkitRequestFullscreen) {
      //     elem.webkitRequestFullscreen();
      //   }
      // }else{
      //   elem?.exitFullscreen();
      // }
    }
  }
};
