import { sendMessage } from "../../utils/WebSocket";

const initialState = () => {
  return {
    maxShakeCount: 0,
    shakeCount: 0,
    questions: {
      Q1: "你有發現我今天哪裡不一樣嗎？",
      Q2: "我最近是不是變胖了？",
      Q3: "就說你決定嘛",
      Q4: "你怎麼都不說話？是不是覺得我很無聊？",
      Q5: "你喜歡胸部大的女生嗎？",
      Q6: "你喜歡我的哪一點？",
      Q7: "我戴這頂帽子可愛嗎？",
      Q8: "我和你媽同時掉進海裡，你會救誰？",
      Q9: "你覺得你有什麼缺點嗎？",
      Q10: "我的手好冰喔"
    },
    talkingContent: require("../../data/datingContent.json"),
    answers: require("../../data/datingAnswer.json"),
    event_lock: false,
    talkingList: [],
    sendCard: [],
    ready: []
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    ready: state => state.ready,
    questions: state => state.questions,
    talkingContent: state => state.talkingContent,
    answers: state => state.answers,
    maxShakeCount: state => state.maxShakeCount,
    event_lock: state => state.event_lock,
    shakeCount: state => state.shakeCount,
    talkingList: state => state.talkingList
  },
  mutations: {
    setReady(state, payload) {
      let check = state.ready.find(d => d.id == payload?.id);
      if (check) {
        state.ready = state.ready.filter(d => d.id != payload?.id);
        return;
      }

      state.ready.push(payload);
    },
    syncReady(state, payload) {
      state.ready = payload;
    },
    push_talking_list(state, talking) {
      state.talkingList.push(talking);
    },
    reset(state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    change_move(state, { player, move }) {
      state[player + "_move"] = move;
    },
    updateMaxShakeCount(state, { step }) {
      step = parseInt(step);
      if (state.maxShakeCount < step) {
        state.maxShakeCount = step;
      }
    },
    set_event_lock(state, status) {
      state.event_lock = status;
    },
    speakTalkingContent(state, talkingIndex) {
      state.talkingContent[talkingIndex].show = true;
    },
    set_talked(state, talking) {
      if (!talking) return;
      state.talkingContent = state.talkingContent.map(d => {
        if (d.stepCount == talking.stepCount) {
          d.show = true;
        }
        return d;
      });
    }
  },
  actions: {
    updateMaxShakeCount({ rootState }, { step }) {
      const data = {
        token: rootState.User.token,
        method: "DatingStepUpdate",
        step
      };
      sendMessage(data);
    },
    setMaxShakeCount({ commit }, { step }) {
      commit("updateMaxShakeCount", { step });
    },
    set_event_lock({ commit }, { status }) {
      commit("set_event_lock", status);
    },
    speakTalkingContent({ commit }, talkingIndex) {
      commit("speakTalkingContent", talkingIndex);
    },
    reset({ commit }) {
      commit("reset");
    },
    setTalked({ commit }, talking) {
      commit("set_talked", talking);
    },
    pushTalkingList({ commit }, talking) {
      // console.log("talkin: ",talking)
      commit("push_talking_list", talking);
    },
    pushQuestionToTalkingList({ rootState }, talking) {
      // commit('push_talking_list',talking)
      const data = {
        token: rootState.User.token,
        method: "DatingPushQuestion",
        talking
      };
      sendMessage(data);
    },
    pushScore({ rootState }, { score, character, user }) {
      // commit('push_talking_list',talking)
      const data = {
        token: rootState.User.token,
        method: "DatingPushScore",
        score,
        character,
        user
      };
      sendMessage(data);
    },
    pushQuestionToTalkingListWebsocket({ commit }, { talking }) {
      commit("push_talking_list", talking);
    },
    setReady({ commit }, { data }) {
      commit("syncReady", data);
    },
    syncReady({ commit }, { data }) {
      commit("syncReady", data);
    },
    sendReady({ rootState }, { character }) {
      const data = {
        token: rootState.User.token,
        method: "DatingReady",
        character
      };
      sendMessage(data);
    }
  }
};
