import {sendMessage} from "../../utils/WebSocket";
import router from "@/router";

const actions = [
  {
    name: "胖子",
    ready: "fighting2_ready.png",
    punch: "fighting2_punch.png",
    defence: "fighting2_def.png",
    beaten: "fighting2_beaten.png",
    skill: "fighting2_skill.png",
    loose: "fighting2_loose.png",
    win: "fighting2_win.png"
  },
  {
    name: "花輪",
    ready: "fighting3_ready.png",
    punch: "fighting3_punch.png",
    defence: "fighting3_def.png",
    beaten: "fighting3_beaten.png",
    skill: "fighting3_skill.png",
    loose: "fighting3_loose.png",
    win: "fighting3_win.png"
  },
  {
    name: "小希",
    ready: "fighting4_ready.png",
    punch: "fighting4_punch.png",
    defence: "fighting4_def.png",
    beaten: "fighting4_beaten.png",
    skill: "fighting4_skill.png",
    loose: "fighting4_loose.png",
    win: "fighting4_win.png"
  },
  {
    name: "赤木",
    ready: "fighting5_ready.png",
    punch: "fighting5_punch.png",
    defence: "fighting5_def.png",
    beaten: "fighting5_beaten.png",
    skill: "fighting5_skill.png",
    loose: "fighting5_loose.png",
    win: "fighting5_win.png"
  },
  {
    name: "白爛",
    ready: "fighting6_ready.png",
    punch: "fighting6_punch.png",
    defence: "fighting6_def.png",
    beaten: "fighting6_beaten.png",
    skill: "fighting6_skill.png",
    loose: "fighting6_loose.png",
    win: "fighting6_win.png"
  },
  {
    name: "春哥",
    ready: "fighting7_ready.png",
    punch: "fighting7_punch.png",
    defence: "fighting7_def.png",
    beaten: "fighting7_beaten.png",
    skill: "fighting7_skill.png",
    loose: "fighting7_loose.png",
    win: "fighting7_win.png"
  }
];

const characterAction = function (characterName, action) {
  let characterSelect = actions.find(people => {
    return people.name == characterName;
  });
  return characterSelect[action];
};
const initialState = () => {
  return {
    player1: null,
    player1_move: "ready",
    player1_animate: null,
    player1_life: 100,
    player1_power: 0,
    player2: null,
    player2_move: "ready",
    player2_animate: null,
    player2_life: 100,
    player2_power: 0,
    game_times: 0,
    supply_times: {
      player1: 0,
      player2: 0
    },
    winner_log: [],
    winners: [],
    winner_lock: false,
    supply_lock: false,
    score_list: []
  };
};

/**
 * @param {string} player2
 */
function setWinner ({ dispatch, rootState, commit }, player) {
  let winner = rootState?.Team?.payload?.fighting?.role?.[player]?.character;
  let user = rootState?.Team?.payload?.fighting?.role?.[player]?.user;
  if (winner && user) {
    commit("winner_log_push", { winner, user });
    dispatch(
      "Favor/setWinner",
      {
        winner: winner,
        user: user,
        stage: 7
      },
      { root: true }
    );
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    player1: state => state.player1,
    player1_move: state => state.player1_move,
    player1_animate: state => state.player1_animate,
    player1_life: state => state.player1_life,
    player1_power: state => state.player1_power,
    player1_life_diff: state => state.player1_life_diff,
    player1_power_diff: state => state.player1_power_diff,
    player2: state => state.player2,
    player2_move: state => state.player2_move,
    player2_animate: state => state.player2_animate,
    player2_life: state => state.player2_life,
    player2_power: state => state.player2_power,
    player2_life_diff: state => state.player2_life_diff,
    player2_power_diff: state => state.player2_power_diff,
    game_times: state => state.game_times,
    supply_times: state => state.supply_times,
    winner_lock: state => state.winner_lock,
    supply_lock: state => state.supply_lock,
    winner_log: state => state.winner_log,
    winners: state => state.winners,
    score_list_in_local: state => state.score_list
  },
  mutations: {
    set_score_list (state, score_list) {
      state.score_list = score_list;
    },
    setWinners (state, winner) {
      state.winners = winner;
    },
    winner_log_push (state, payload) {
      state.winner_log.push(payload);
    },
    reset (state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    set_winner_lock (state) {
      state.winner_lock = true;
    },
    set_supply_lock (state) {
      state.supply_lock = true;
    },
    add_supply_times (state, player) {
      let count = state.supply_times?.[player] ?? 0;
      state.supply_times[player] = count + 1;
    },
    reset_supply_times (state) {
      state.supply_times = {
        player1: 0,
        player2: 0
      };
    },
    increment_game_times (state, data) {
      if (data?.incrementGameTimes) {
        state.game_times = data.incrementGameTimes;
        return;
      }
      state.game_times = state.game_times + 1;
    },
    change_move (state, { player, move }) {
      state[player + "_move"] = move;
    },
    add_life (state, { player, life }) {
      state[player + "_life"] += life;
      if (state[player + "_life"] < 0) {
        state[player + "_life"] = 0;
      }
    },
    set_life (state, { player, life }) {
      state[player + "_life"] = life;
    },
    set_default: function (state) {
      state.player1_life = 100;
      state.player2_life = 100;
      state.player1_power = 0;
      state.player2_power = 0;

      state.supply_times = {
        player1: 0,
        player2: 0
      };
      state.player1_life_diff = 0;
      state.player1_power_diff = 0;
      state.player2_life_diff = 0;
      state.player2_power_diff = 0;
    },
    sync_life: function (state, { p1_life, p2_life, p1_power, p2_power }) {
      state.player1_life_diff = p1_life;
      state.player1_power_diff = p1_power;
      state.player2_life_diff = p2_life;
      state.player2_power_diff = p2_power;
    },
    real_sync_life: function (state) {
      state.player1_life += state.player1_life_diff;
      state.player2_life += state.player2_life_diff;
      state.player1_power += state.player1_power_diff;
      if (state.player1_power > 100) {
        state.player1_power = 100;
      }
      state.player2_power += state.player2_power_diff;
      if (state.player2_power > 100) {
        state.player2_power = 100;
      }
      state.player1_life_diff = 0;
      state.player1_power_diff = 0;
      state.player2_life_diff = 0;
      state.player2_power_diff = 0;
    },
    set_power (state, { player, power }) {
      state[player + "_power"] = power;
    },
    player_move (state, { player, move }) {
      state[player + "_move"] = move;
    },
    player_animate (state, { player, move }) {
      state[player + "_animate"] = move;
    }
  },
  actions: {
    setWinners ({ rootState }, winner) {
      const data = {
        method: "SetFightWinners",
        token: rootState.User.token,
        winners: winner
      };
      sendMessage(data);
    },
    setWinnersWebsocket ({ commit }, { winners }) {
      commit("setWinners", winners);
    },
    addLife () {
    },
    addPower () {
    },
    playerMove ({ commit, dispatch }, { player, move }) {
      commit("player_move", { player, move });
      dispatch("setPlayerAnimate", { player, move });
    },
    setPlayerAnimate ({ commit, dispatch }, { player, character, move }) {
      let animate = characterAction(character, move);
      commit("player_animate", {
        player,
        move: animate
      });

      if (!["ready", "win", "loose"].includes(move)) {
        let delay = move == "skill" ? 3000 : 1000;
        setTimeout(() => {
          commit("player_move", { player, move: "ready" });
          dispatch("setPlayerAnimate", { player, move: "ready", character });
          if (move != "beaten") {
            commit("real_sync_life");
          }
        }, delay);
      }
    },
    fightMove ({ rootState }, { character, move, player }) {
      const data = {
        method: "Fighting",
        token: rootState.User.token,
        character,
        player,
        move
      };
      sendMessage(data);
    },
    charge ({ rootState }, { player }) {
      if (!player) return;
      const data = {
        method: "FightingSupply",
        token: rootState.User.token,
        player
      };
      sendMessage(data);
    },
    setCharge ({ commit }, { data: { player, power } }) {
      commit("set_power", { player, power });
    },
    syncLife ({ commit }, { p1_life, p2_life, p1_power, p2_power }) {
      commit("sync_life", { p1_life, p2_life, p1_power, p2_power });
    },
    setDefault ({ commit }) {
      commit("set_default");
    },
    showWinner ({ dispatch }, { winner, user }) {
      dispatch(
        "Favor/setWinner",
        {
          winner: winner,
          user: user,
          stage: 7
        },
        { root: true }
      );
    },
    gameOver ({ dispatch, commit, rootState }, { player1_character, player2_character, player1_life, player2_life }) {
      let player1_move = "win";
      let player2_move = "win";

      if (player1_life <= 0) {
        player1_move = "loose";
        setWinner({ dispatch, rootState, commit }, "player2");
        commit("set_life", { player: "player1", life: 0 });
        // dispatch("Stage/setFlag", { key: "stage_4_fightingWinner", status: player2_character }, { root: true });
      }
      if (player2_life <= 0) {
        player2_move = "loose";
        setWinner({ dispatch, rootState, commit }, "player1");
        commit("set_life", { player: "player2", life: 0 });
        // dispatch("Stage/setFlag", { key: "stage_4_fightingWinner", status: player1_character }, { root: true });
      }
      if (player1_life > 0 && player1_life < player2_life) {
        player1_move = "loose";
        setWinner({ dispatch, rootState, commit }, "player2");
      }

      if (player2_life > 0 && player1_life > player2_life) {
        player2_move = "loose";
        setWinner({ dispatch, rootState, commit }, "player1");
      }

      const data = {
        method: "FightingTimeOver",
        token: rootState.User.token
      };
      sendMessage(data);

      dispatch("setPlayerAnimate", {
        character: player1_character,
        move: player1_move,
        player: "player1"
      });
      dispatch("setPlayerAnimate", {
        character: player2_character,
        move: player2_move,
        player: "player2"
      });
    },
    incrementGameTimes ({ commit }, { data }) {
      commit("increment_game_times", data);
    },
    addSupplyTimes ({ commit }, player) {
      commit("add_supply_times", player);
    },
    supplyGoal ({ state }, winner) {
      let other = winner == "player1" ? "player2" : "player1";
      if (state.supply_lock == false) {
        if (state.supply_times[winner] > state.supply_times[other]) {
          // dispatch("Team/setUserScore", {score: 5}, {root: true});
          // commit("set_supply_lock");
        }
      }
    },
    fightingGoal ({ state }, winner) {
      let other = winner == "player1" ? "player2" : "player1";
      if (state.winner_lock == false) {
        if (state[winner + "_life"] > state[other + "_life"]) {
          // dispatch("Team/setUserScore", {score: 10}, {root: true});
          // commit("set_winner_lock");
        }
      }
    },
    setScoreList ({ commit }, score_list) {
      commit("set_score_list", score_list);
    },
    async fightAgain ({ dispatch }, payload) {
      await dispatch('Team/setPayload', payload, { root: true })
      await router.replace('fightingStandBy')
    },
    async move ({ dispatch }, payload) {
      await dispatch('setPlayerAnimate', payload)
      const action_data = payload.action_data
      if (action_data.p1_life < 0) {
        let data = {
          'namespace': 'Fight',
          'action': 'setPlayerAnimate',
          'move': 'beaten',
          'player': 'player1',
          'character': payload.data['player1_character'],
        }
        await dispatch('setPlayerAnimate', data)
      }
      if (action_data.p2_life < 0) {
        let data = {
          'namespace': 'Fight',
          'action': 'setPlayerAnimate',
          'move': 'beaten',
          'player': 'player2',
          'character': payload.data['player2_character'],
        }
        await dispatch('setPlayerAnimate', data)
      }
    }
  }
};
