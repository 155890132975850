import { sendMessage } from "../../utils/WebSocket";

export default {
  namespaced: true,
  state: {
    photos: {},
    upload_map: {}
  },
  getters: {
    photos: state => state.photos,
    upload_map: state => state.upload_map,
    stagePhoto: state => stage => {
      return state.photos?.[stage.toString()] || state.photos?.[stage];
    }
  },
  mutations: {
    reset(state) {
      state.photos = {};
      state.upload_map = {};
    },
    updatePhoto(state, { photo, stage }) {
      let stage_id = stage?.stage_id || stage;
      let photo_container = {};
      photo_container[stage_id] = photo;
      state.photos = { ...state.photos, ...photo_container };
    },
    setUploadMap(state, { stage }) {
      let count = state.upload_map?.[stage] || 0;
      state.result_map[stage] = count + 1;
    }
  },
  actions: {
    reset({ commit }) {
      commit("reset");
    },
    updatePhoto({ commit }, { photo, stage }) {
      commit("updatePhoto", { photo, stage });
    },
    syncAll({commit},resultMap){
      for(let stage_id in resultMap){
        commit("updatePhoto", { photo:resultMap[stage_id], stage:stage_id });
      }
    },
    syncPhoto({ rootState }, { photo, stage }) {
      stage = stage?.stage_id || stage
      const data = {
        token: rootState.User.token,
        method: "ResultPhotoSync",
        photo,
        stage
      };
      sendMessage(data);
    }
  }
};
