<template lang="pug">
.app-info-modal(v-if="infoModal.is_show")
  h3.text-center(v-if="title.length") {{ title }}
  div.content(v-html="content")
  .pt-3.text-center
    button.px-5#modal-confirm(class="btn btn-primary text-white" @click="closeModal") 我知道了
  
</template>

<script>
import ModalMixin from "../../Mixins/ModalMixin";

export default {
  name: "modal",
  mixins: [ModalMixin],
  computed: {
    content() {
      return this.infoModal?.content || "";
    },
    title() {
      return this.infoModal?.title || "";
    }
  }
};
</script>
<style lang="scss" scoped>
div.content {
  line-height: 28px;
}
</style>
