import { sendMessage } from "../../utils/WebSocket";

const initialState = function() {
  return {
    flags: {
      tofu: false,
      beef: false,
      douhua: false,
      lautianlu: false,
      noodles: false,
      olympiafoods: false,
      pastry: false,
      pepperCake: false,
      plum: false,
      porkrice: false,
      starfruit: false,
      xiaolongbao: false
    }
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    flags: state => state.flags,
    flag: state => key => state?.flags?.[key]
  },
  mutations: {
    reset: state => {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    SET_FLAG: (state, { key, status }) => {
      state.flags[key] = status;
    }
  },
  actions: {
    setFlag({ commit, rootState,stage }, { key, status }) {
      commit("SET_FLAG", { key, status });
      let payload = {
        ...stage,
        [key]:status
      }
      const data = {
        token: rootState.User.token,
        method: "SaveFoodFlag",
        payload
      };
      sendMessage(data);
    }
  }
};
