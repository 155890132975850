<template>
  <div>
    <div class="full-video position-fixed d-flex align-items-center">
    </div>
  </div>
</template>

<script>

export default {
  name: "Loading",

  data() {
    return {
      playBtn: true
    };
  },
  methods: {
    play() {
      this.playBtn = false;
    },
    goMap: function() {
      this.$router.replace("homeMap");
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
