import { sendMessage } from "../../utils/WebSocket";
import router from "../../router";
import groupBy from "lodash/groupBy";

const initialState = function() {
  return {
    directions: [],
    caught: [],
    ready: [],
    over: false,
    hideTimerCount:180,
    findTimerCount: 600,
    default_data:{
      hideTimerCount:180,
      findTimerCount: 600,
    }
  };
};

/**
 * @param {string} key
 * @param {boolean} value
 */
function setFlag (commit, key = "stage_7_ghost_over", value = true) {
  commit(
    "Stage/SET_FLAG",
    {
      key: key,
      status: value
    },
    { root: true }
  );
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    over: state => state.over,
    directions: state => state.directions,
    ready: state => state.ready,
    caught: state => state.caught,
    hideTimerCount: state => state.hideTimerCount,
    findTimerCount: state => state.findTimerCount,
    only_one_survived: state => {
      return state.caught.length >= Object.values(state.directions).length - 1;
    }
  },
  mutations: {
    setHideTimerCount(state,count){
      state.hideTimerCount = count
    },
    setFindTimerCount(state,count){
      state.findTimerCount = count
    },
    reset: state => {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    setOver(state) {
      state.over = true;
    },
    setReady(state, payload) {
      let check = state.ready.find(d => d.id == payload?.id);
      if (check) {
        state.ready = state.ready.filter(d => d.id != payload?.id);
        return;
      }

      state.ready.push(payload);
    },
    syncReady(state, payload) {
      state.ready = payload;
    },
    updateDirections(state, data) {
      state.directions = data;
    },
    setCaught(state, { caught, bywho }) {
      state.caught = state.caught || [];
      let user_has_be_caught = state.caught.find(d => d.caught == bywho);
      if (user_has_be_caught) return;
      let caught_has_be_caught = state.caught.find(d => d.caught == caught);
      if (caught_has_be_caught) return;
      let all_ready_caught = state.caught.find(d => d.caught == caught && d.bywho == bywho);
      if (all_ready_caught) return;

      state.caught.push({
        caught,
        bywho
      });
    }
  },
  actions: {
    setFindTimerCount({commit},count){
      commit('setFindTimerCount',count)
    },
    setHideTimerCount({commit},count){
      commit('setHideTimerCount',count)
    },
    reset({ commit, dispatch }) {
      commit("reset");
      dispatch(
        "Stage/setFlag",
        {
          key: "stage_7_ghost_over",
          status: false
        },
        { root: true }
      );
      dispatch(
        "Stage/setFlag",
        {
          key: "stage_7_favor",
          status: false
        },
        { root: true }
      );
    },
    startGhost({ rootState }) {
      const data = {
        token: rootState.User.token,
        method: "GhostStart"
      };
      sendMessage(data);
    },
    goGhost({ commit }) {
      commit("reset");
      router.replace("/ghost");
    },
    updateEveryone({ commit }, { data,time,start_time }) {
      commit("updateDirections", data);
      commit('setFindTimerCount',600-(time-start_time))
    },
    updateOne({ commit }, { data,time,start_time }) {
      commit("updateDirections", data);
      commit('setFindTimerCount',600-(time-start_time))
    },
    catch({ rootState }, { direction, talkingLabel, character }) {
      const data = {
        token: rootState.User.token,
        method: "GhostCatch",
        direction,
        talkingLabel,
        character
      };
      sendMessage(data);
    },
    someoneBeCaught({ commit }, { data }) {
      commit("setCaught", data);
    },
    setReady({ commit }, { data }) {
      commit("setReady", data);
    },
    sendReady({ rootState }, { character }) {
      const data = {
        token: rootState.User.token,
        method: "GhostReady",
        character
      };
      sendMessage(data);
    },
    async setGameOver({ commit,dispatch },{winner,winner_data,score_data}) {
      await setFlag(commit,'stage_7_ghostWinner',winner);
      await dispatch('Favor/setWinnerFromWebsocket',
        {data:winner_data},
        {root:true}
      )
      await setFlag(commit,'stage_7_ghost_over',true);
      await dispatch('Score/setUserScoreWebsocket',{
        data:score_data,
      },{root:true})
    },
    syncReady({ commit }, { data }) {
      commit("syncReady", data);
    }
  }
};
