import { isIOS } from "@/utils/OS";

export const power = function(event) {
  let x = event?.acceleration?.x;
  let y = event?.acceleration?.y;
  let z = event?.acceleration?.z;
  if (isIOS()) {
    x = x * 9.81;
    y = y * 9.81;
    z = z * 9.81;
  }
  let sum = Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2);
  return Math.sqrt(sum);
};

export const checkMotionPermission = function() {
  return new Promise(function(resolve) {
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      DeviceMotionEvent.requestPermission().then(function(permissionState) {
        resolve(permissionState === "granted");
      });
    } else {
      // handle regular non iOS 13+ devices
      resolve(true);
    }
  });
};

export const setDeviceMotionEvent = function(call, errCall) {
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    DeviceMotionEvent.requestPermission()
      .then(permissionState => {
        if (permissionState === "granted") {
          window.addEventListener("devicemotion", call);
        }
      })
      .catch(errCall);
  } else {
    // handle regular non iOS 13+ devices
    window.addEventListener("devicemotion", call);
  }
};

export const getDeviceMotionPermission = function() {
  if (typeof DeviceMotionEvent.requestPermission === "function") {
    DeviceMotionEvent.requestPermission();
  }
};

export const removeDeviceMotionEvent = function() {
  window.removeEventListener("devicemotion");
};
