<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <svg class="d-none">
      <symbol id="icon-fb">
        <path
          class=""
          d="M14.7,11.2l0.6-3.6h-3.5V5.3c0-1,0.5-2,2-2h1.6V0.2c0,0-1.4-0.2-2.8-0.2C9.7,0,7.8,1.7,7.8,4.9v2.8H4.6v3.6h3.2
	V20h3.9v-8.8H14.7z"
        />
      </symbol>
      <symbol id="icon-google">
        <path
          class=""
          d="M19.5,10.1c0,5.6-3.8,9.6-9.5,9.6c-5.4,0-9.8-4.4-9.8-9.8S4.6,0,10,0c2.6,0,4.9,1,6.6,2.6l-2.7,2.6
	c-3.5-3.4-10-0.8-10,4.7c0,3.4,2.7,6.2,6.1,6.2c3.9,0,5.4-2.8,5.6-4.2H10V8.4h9.4C19.4,8.9,19.5,9.4,19.5,10.1L19.5,10.1z"
        />
      </symbol>
      <symbol id="arrow-left">
        <path
          class=""
          d="M5.3,9.4L12.6,2c0.4-0.4,0.9-0.4,1.3,0l0.9,0.9c0.4,0.4,0.4,0.9,0,1.3L8.9,10l5.8,5.8c0.4,0.4,0.4,0.9,0,1.3
	L13.9,18c-0.4,0.4-0.9,0.4-1.3,0l-7.3-7.3C4.9,10.3,4.9,9.7,5.3,9.4z"
        />
      </symbol>
      <symbol id="arrow-right">
        <path
          class=""
          d="M14.7,10.6L7.4,18c-0.4,0.4-0.9,0.4-1.3,0l-0.9-0.9c-0.4-0.4-0.4-0.9,0-1.3l5.8-5.8L5.3,4.2
	c-0.4-0.4-0.4-0.9,0-1.3L6.1,2C6.5,1.7,7,1.7,7.4,2l7.3,7.3C15.1,9.7,15.1,10.3,14.7,10.6z"
        />
      </symbol>
      <symbol id="icon-edit">
        <path id="" d="M0 0H11V11H0z" data-name="Rectangle 324" transform="translate(.5 .5)" style="fill:none" />
        <path
          id=""
          d="M5.585 2.107l-3.9 4L0 10.959l4.847-1.581 3.9-3.9zm4.953-.316L9.168.421a1.278 1.278 0 0 0-1.9 0l-1.156 1.16 3.161 3.266 1.264-1.264a1.336 1.336 0 0 0 .421-.948 1.3 1.3 0 0 0-.421-.844z"
          data-name="Path 110"
          style=""
        />
      </symbol>
      <symbol id="icon-user">
        <path
          id=""
          class=""
          d="M2.5,21.5v-3.4c0-3.2,3.2-5.9,7.4-6.6c-2.4-1.2-3.3-4-2.2-6.4c1.2-2.4,4-3.3,6.4-2.2
	s3.3,4,2.2,6.4c-0.5,0.9-1.2,1.7-2.2,2.2c4.2,0.7,7.4,3.4,7.4,6.6v3.4H2.5z"
        />
      </symbol>
      <symbol id="icon-team">
        <g>
          <path
            class=""
            d="M6.4,12.3L8,13.1l1.5-0.7c0.7-0.3,1.2-0.9,1.6-1.6c0.2-0.3,0.2-0.7,0.3-1l0,0c0,0,0,0,0,0c0.2-1.1-0.2-2.2-1-3
		c0,0,0,0,0,0v0c-0.2-0.2-0.5-0.4-0.8-0.6C9,6,8.5,5.9,8,5.9c-1.3,0-2.5,0.7-3.1,1.9c-0.4,0.8-0.5,1.7-0.2,2.6S5.6,11.9,6.4,12.3z"
          />
          <path
            class=""
            d="M9.7,13.7l-1.7-0.3l-1.7,0.3c-3.4,0.6-5.9,2.7-5.9,5.1V21l5,0l0,0l10.2,0v-2.2C15.5,16.4,13.1,14.3,9.7,13.7z"
          />
          <path
            class=""
            d="M16.8,11.9l-1.5-0.3l1.4-0.7c0.9-0.4,1.6-1.1,2-2c1.1-2.2,0.2-4.9-2-6c-0.6-0.3-1.3-0.5-2-0.5
		c-0.5,0-1,0.1-1.4,0.2c-1.1,0.4-2,1.2-2.6,2.2c0,0.1-0.1,0.2-0.1,0.2c1.8,1.2,2.6,3.5,1.9,5.6c0.1,0,0.1,0.1,0.2,0.1l1.4,0.7
		l-1.5,0.3c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0.2-0.3,0.5-0.5,0.7c3.3,1,5.5,3.5,5.5,6.2v2.5l7.1,0v-3C24.1,15.2,21.1,12.5,16.8,11.9z"
          />
        </g>
      </symbol>
      <symbol id="icon-intro">
        <path
          id=""
          class=""
          d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M11.9,18.6
	c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4l0,0C13.3,18,12.7,18.6,11.9,18.6L11.9,18.6z M14.6,10.8L13.5,12
	c-0.3,0.2-0.4,0.6-0.4,0.9c0,0.7-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.1v-0.1c0-0.7,0.3-1.4,0.8-1.8l0.9-1.1
	c0.4-0.4,0.6-0.9,0.7-1.4c0-0.6-0.4-1.1-1-1.1H12c-0.6,0-1.1,0.4-1.2,0.9c-0.2,0.4-0.7,0.7-1.2,0.7c-0.6,0-1.1-0.4-1.1-1V8
	c0-0.2,0-0.3,0.1-0.5c0.5-1.4,1.9-2.2,3.4-2.1c1.8-0.1,3.3,1.2,3.5,3c0,0.1,0,0.2,0,0.2C15.5,9.4,15.2,10.2,14.6,10.8z"
        />
      </symbol>
      <symbol id="icon-heart">
        <path
          id=""
          class=""
          d="M38.5,5.1C34.5,1.1,28,1.1,24,5c0,0-0.1,0.1-0.1,0.1l-1.4,1.4l-1.4-1.4
		c-4-4-10.5-4-14.5,0s-4,10.5,0,14.5l16,16l16-16C42.5,15.7,42.5,9.2,38.5,5.1C38.5,5.2,38.5,5.1,38.5,5.1"
        />
      </symbol>
      <symbol id="icon-heart-s">
        <path
          id=""
          d="M20.083 1.768a5.857 5.857 0 0 0-8.337 0l-.82.82-.82-.82a5.9 5.9 0 0 0-8.337 8.337l9.157 9.157 9.157-9.157a5.857 5.857 0 0 0 0-8.337"
          data-name="Heart"
          transform="translate(2.98 -.03)"
        />
      </symbol>
      <symbol id="icon-map-location" viewBox="0 4.5 27 18">
        <path
          class=""
          d="M27.5,22.6l-4.6-8.6c-0.1-0.2-0.3-0.3-0.4-0.3h-3.4c0.7-0.9,1-2.1,1.1-3.2C20,7.2,17.3,4.6,14,4.7
	c-3.3-0.1-6,2.5-6.2,5.8c0,1.2,0.4,2.3,1.1,3.2H5.8c-0.2,0-0.3,0.1-0.4,0.3l-4.8,8.6c-0.1,0.2-0.1,0.3,0,0.5s0.3,0.2,0.4,0.2h26.1
	c0.2,0,0.3-0.1,0.4-0.2C27.6,22.9,27.6,22.7,27.5,22.6z M13.9,8.2c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5
	C11.4,9.3,12.5,8.2,13.9,8.2z M1.8,22.3l4.3-7.6h3.5c0.1,0.2,0.1,0.4,0.2,0.5l2.5,3.8c0.2,0.2,0.4,0.4,0.6,0.6
	c0.9,0.6,2.2,0.3,2.8-0.6l2.5-3.8c0.1-0.2,0.2-0.3,0.2-0.5h3.8l4.1,7.6H1.8z"
        />
      </symbol>
      <symbol id="icon-up">
        <path id="" class="" d="M4.9,14l1.7,1.7l6.3-6.3v13.8h2.4V9.4l6.3,6.3l1.7-1.7L14,4.9L4.9,14z" />
      </symbol>
      <symbol id="icon-team-location">
        <path
          class=""
          d="M21.5,8.7c-2.8,0-5,2.2-5,4.8c0,1.2,0.5,2.4,1.4,3.3l0.2,0.2l0.1,0.3c0,0.1,0.1,0.2,0.1,0.4l2.4,3.8
	c0.2,0.3,0.5,0.5,0.8,0.5s0.7-0.2,0.8-0.5l2.4-3.8c0.1-0.1,0.1-0.2,0.1-0.4l0.1-0.3l0.2-0.2c0.9-0.9,1.4-2.1,1.4-3.3
	C26.5,10.9,24.3,8.7,21.5,8.7z M21.5,15.4c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S22.6,15.4,21.5,15.4z"
        />
        <path
          class=""
          d="M23.2,22.1c-0.4,0.6-1,0.9-1.7,0.9c-0.7,0-1.3-0.3-1.7-0.9l-2.4-3.8c-0.1-0.2-0.2-0.5-0.3-0.7
	c-1.1-1.1-1.6-2.5-1.6-4c0-2.8,2-5.1,4.7-5.7c-0.2-1.7-1.3-3.2-2.9-4c-2.6-1.3-5.8-0.2-7,2.4c-0.1,0.1-0.1,0.3-0.2,0.4
	C8,5.9,5.5,6.8,4.5,8.9c-0.9,1.8-0.4,4,1,5.3C2.2,15.1,0,17.4,0,20v3.4h5h12h8v-3.9c0-0.1,0-0.2,0-0.3L23.2,22.1z M16,22.4H1V20
	c0-2.4,2.4-4.5,5.8-5.1l1.5-0.3L7,14c-1.7-0.9-2.4-3-1.6-4.7c0.9-1.7,3-2.4,4.7-1.6c0.8,0.4,1.5,1.1,1.8,2c0.3,0.9,0.2,1.8-0.2,2.7
	c-0.3,0.7-0.9,1.2-1.6,1.6l-1.4,0.7l1.5,0.3c3.4,0.5,5.8,2.7,5.8,5.1V22.4z"
        />
      </symbol>
      <symbol id="icon-more">
        <g id="" transform="translate(-136 -636)" class="">
          <circle id="" cx="140" cy="648" r="2.5" />
          <circle id="" cx="148" cy="648" r="2.5" />
          <circle id="" cx="156" cy="648" r="2.5" />
        </g>
      </symbol>
      <symbol id="icon-tip">
        <g>
          <path
            class=""
            d="M26.7,20.8c-1.7-1.3-2.7-3.2-2.7-5.3v-5c0,0,0,0,0,0c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10v5
		c0,2.1-1,4.1-2.7,5.3c-0.5,0.5-0.7,1.2-0.5,1.8c0.2,0.7,0.8,1.2,1.5,1.2h8.3c0,1.8,1.5,3.3,3.3,3.3c1.8,0,3.3-1.5,3.3-3.3h8.3
		c0.7,0,1.3-0.5,1.5-1.2C27.5,22,27.3,21.2,26.7,20.8z M12.1,9.8h-1.5V14h-1V9.8H8.2V9h4V9.8z M13.8,14h-1V9h1V14z M18.4,11.3
		c-0.1,0.2-0.3,0.4-0.4,0.5c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.6,0.1-1,0.1h-0.7V14h-1V9h1.6c0.6,0,1,0,1.2,0.1
		c0.3,0.1,0.5,0.2,0.7,0.5c0.2,0.3,0.3,0.6,0.3,1C18.6,10.8,18.5,11.1,18.4,11.3z"
          />
          <path
            class=""
            d="M17,9.9c-0.1,0-0.4,0-0.7,0h-0.5v1.4h0.6c0.4,0,0.7,0,0.8-0.1c0.1-0.1,0.2-0.1,0.3-0.2
		c0.1-0.1,0.1-0.2,0.1-0.4c0-0.2-0.1-0.3-0.2-0.5C17.3,10,17.1,9.9,17,9.9z"
          />
        </g>
      </symbol>
      <symbol id="icon-back">
        <path id="" class="" d="M3.8,9l9-9l2.7,2.7L9.2,9l6.3,6.3L12.8,18L3.8,9z" />
      </symbol>
      <symbol id="icon-user-setting">
        <g>
          <path
            d="M5.3,11.5c0.3,0.9,0.9,1.6,1.7,2l1.5,0.7l1.5-0.7c0.7-0.3,1.2-0.9,1.6-1.6c0.8-1.7,0.1-3.7-1.6-4.6C9.5,7.1,9,7,8.5,7
	C7.2,7,6,7.7,5.4,8.9C5,9.7,5,10.6,5.3,11.5z"
          />
          <path d="M0.9,22.1l15.2,0v-2.2c0-2.4-2.5-4.5-5.9-5.1l-1.7-0.3l-1.7,0.3c-3.4,0.6-5.9,2.7-5.9,5.1V22.1z" />
          <path
            d="M21.5,17c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2C22.7,17.5,22.1,17,21.5,17z"
          />
          <path
            d="M12.6,13.1c-0.2,0.2-0.3,0.5-0.5,0.7c1,0.3,1.9,0.8,2.7,1.4l0.9-1.7l2.1,0.4c0.3-0.2,0.6-0.4,0.9-0.6
	c-0.5-0.1-0.9-0.2-1.4-0.3l-1.5-0.3l1.4-0.7c0.9-0.4,1.6-1.1,2-2c1.1-2.2,0.2-4.9-2-6c-0.6-0.3-1.3-0.5-2-0.5c-0.5,0-1,0.1-1.4,0.2
	c-1.1,0.4-2,1.2-2.6,2.2c0,0.1-0.1,0.2-0.1,0.2c1.9,1.2,2.6,3.5,1.9,5.6c0.1,0,0.1,0.1,0.2,0.1l1.4,0.7L13.2,13
	C13,13,12.8,13.1,12.6,13.1z"
          />
          <path
            d="M23,22.5c0.7-0.2,1.3-0.5,1.8-1l0.2-0.2l1.9,0.4l0.5-0.9l-1.2-1.4v-0.2c0-0.2,0-0.4,0-0.6c0-0.2,0-0.3,0-0.5
	c0-0.3,0-0.7-0.1-1l0-0.2l1.3-1.4l-0.5-0.9L25,15l-0.2-0.2c-0.5-0.5-1.2-0.8-1.9-1l-0.2-0.1l-0.6-1.8h-1l-0.6,1.9l-0.3,0.1
	c-0.7,0.2-1.3,0.5-1.8,1L18.2,15l-1.9-0.4l-0.5,0.9l1.2,1.4V17c0,0.2,0,0.4,0,0.6c0,0.2,0,0.3,0,0.5c0,0.3,0,0.7,0.1,1l0,0.2
	l-1.3,1.4l0.5,0.9l1.9-0.4l0.2,0.2c0.5,0.5,1.2,0.8,1.9,1l0.2,0.1l0.6,1.8h1l0.6-1.9L23,22.5z M21.5,20.3L21.5,20.3
	c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C23.7,19.4,22.7,20.3,21.5,20.3z"
          />
        </g>
      </symbol>
      <symbol id="icon-logout">
        <path d="M0,0h24v24H0V0z" fill="none" />
        <path
          d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z"
        />
      </symbol>
    </svg>
    <modal></modal>
    <info-modal></info-modal>
    <ShowWebsocketConnection v-if="showWebsocketStatus"></ShowWebsocketConnection>
    <!--    <ShowConnectionMap></ShowConnectionMap>-->
  </div>
</template>
<script>
import modal from "./views/part/modal";
import infoModal from "./views/part/InfomationModal";
import ShowWebsocketConnection from "@/components/ShowWebsocketConnection";
// import ShowConnectionMap from "@/components/ShowConnectionMap";

export default {
  computed: {
    isPermission() {
      return this.$store.state.isPermission;
    },
    showWebsocketStatus() {
      return !["/test", "/checklist"].includes(this.$route.path);
    }
  },
  components: {
    modal,
    infoModal,
    ShowWebsocketConnection
    // ShowConnectionMap
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_global.scss";
</style>
