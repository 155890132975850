import router from "@/router";
import Vue from "vue";
import { sendMessage } from "@/utils/WebSocket";
import { getDistance } from "@/Mixins/getDistance";
import md5 from "js-md5";
import stages from "@/store/modules/data/stages";
import locations from "@/store/modules/data/locations";
import flags from "@/store/modules/flag/flags";

const initialState = () => {
  return {
    stages,
    locations,
    /*
    stationStage: {
      finish: false,
      name: "台北車站",
      position: [25.047207, 121.516971],
      bounds: [
        [25.048889, 121.515442],
        [25.048071, 121.520703],
        [25.045597, 121.519591],
        [25.04682, 121.514989]
      ]
    },*/
    flags,
    local_flags: {
      // stage_2_dance_bonus: false,
      map_open: false,
      // stage_7_check1stPlace: false,
      usedCard: [3, 18, 11],
      // stage_5_fgClear: false
    }
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    flags_check: state => keys => {
      let container = {};
      for (let key in keys) {
        container[key] = state?.flags?.[key];
      }
      return md5(JSON.stringify(container));
    },
    flags: state => state.flags,
    flag: state => key => state?.flags?.[key],
    localFlag: state => key => state?.local_flags?.[key],
    stages: state => state.stages,
    locations: state => state.locations,
    //stationStage: state => state.stationStage,
    locations_unlock: state => userCenter => {
      return state.locations
        .filter(location => {
          if (!location?.hideOnMap) {
            console.log("location.unlockFlag2= " + location.unlockFlag2);
            console.log(location);
            return location.unlock || state.flags[location.unlockFlag] || state.flags[location.unlockFlag2];
          }
        })
        .map(location => {
          if (location.localFlag && state.flags?.[location.localFlag]) {
            location.local = state.flags[location.localFlag];
          }
          location.data = getDistance(userCenter[0], userCenter[1], location.local[0], location.local[1], "M");
          if (!location?.icon) {
            location["icon"] = {
              iconUrl: "/img/icon-pin.svg"
            };
          }
          return location;
        });
    }
  },
  mutations: {
    SET_FLAG: (state, { key, status }) => {
      // state.flags[key] = status;
      Vue.set(state.flags, key, status);
    },
    SET_LOCAL_FLAG: (state, { key, status }) => {
      // state.flags[key] = status;
      Vue.set(state.local_flags, key, status);
    },
    reset: state => {
      const initial = initialState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
    },
    SYNC_FLAGS: function(state, flags) {
      // state.flags = {}
      let newFlags = { ...state.flags, ...flags };
      // state.flags = { ...state.flags, ...flags };
      Vue.set(state, "flags", newFlags);
    },
    set_stage_status: (state, { stage_id, status }) => {
      state.stages[stage_id].status = status;
    },
    set_stage_step_status: (state, { stage_id, step, status }) => {
      state.stages[stage_id].step[step] = status;
    },
    set_stages: (state, payload) => (state.stages = payload),
    set_locations: (state, payload) => (state.locations = payload)
    //set_stationStage: (state, payload) => (state.stationStage = payload)
  },
  actions: {
    flagSync({ rootState }, { key, status, user }) {
      const data = {
        token: rootState.User.token,
        method: "FlagSync",
        key,
        status,
        only: user?.id
      };
      sendMessage(data);
    },
    setLocalFlag({ commit }, { key, status }) {
      commit("SET_LOCAL_FLAG", { key, status });
    },
    setFlag({ commit, dispatch }, { key, status, delay, user }) {
      if (delay) {
        setTimeout(function() {
          commit("SET_FLAG", { key, status, user });
          dispatch("flagSync", { key, status, user });
        }, delay);
        return;
      }
      commit("SET_FLAG", { key, status, user });
      dispatch("flagSync", { key, status, user });
    },
    flagsSync({ commit }, flags) {
      commit("SYNC_FLAGS", flags);
    },
    flagsAllSync({ commit }, { flags }) {
      if(flags){
        commit("SYNC_FLAGS", flags);
      }
    },
    forceSyncRemoteFlags({ rootState }){
      let message = {
        method: "SyncAllFlag",
        token: rootState.token
      };
      sendMessage(message);
    },
    setFlagByWebsocket({ commit }, { data: { key, status = true } }) {
      commit("SET_FLAG", { key, status });
    },
    goto(context, { data: { path } }) {
      router.replace(path);
    },
    gotoMap() {
      router.replace("/homeMap");
    },
    gotoLocation(context, { data: { location } }) {
      if (router?.currentRoute && router.currentRoute?.path) {
        let path = router.currentRoute?.path.toLowerCase();
        if (path.includes(location?.link)) return;
      }
      router.replace('/'+location.link);
    },
    showStageMessage({ commit }, payload) {
      commit("Team/SET_PAYLOAD", payload?.data?.team_payload, { root: true });
    },
    setStageStatus({ commit }, payload) {
      let stage_id = payload?.data?.stage_id;
      let status = payload?.data?.status;
      let step = payload?.data?.step;
      if (step) {
        commit("set_stage_step_status", { stage_id, status, step });
      } else {
        commit("set_stage_status", { stage_id, status });
      }
      // router.replace('homeMap');
    },
    syncStage({ commit }, payload) {
      commit("set_stages", payload?.data?.stages);
      commit("set_locations", payload?.data?.locations);
      //commit("set_stationStage", payload?.data?.stationStage);
    },
    stageUnlock(context, { stage, step, data, newMsg }) {
      const messageData = {
        method: "StageUnlock",
        token: this.token,
        stage: stage,
        step: step,
        data,
        newMsg
      };
      sendMessage(messageData);
    },
    sendStageMessage({ rootState }, { step, stage, newMsg }) {
      const messageData = {
        method: "StageMessage",
        token: rootState.User.token,
        step,
        stage,
        newMsg
      };
      sendMessage(messageData);
    },
    fightingMove({ rootState }, { step, stage, newMsg }) {
      const messageData = {
        method: "Fighting",
        token: rootState.User.token,
        step,
        stage,
        newMsg
      };
      sendMessage(messageData);
    },
    fightingSupply({ rootState }, { step, stage, newMsg }) {
      const messageData = {
        method: "Fighting",
        token: rootState.User.token,
        step,
        stage,
        newMsg
      };
      sendMessage(messageData);
    },
    fightingChoseRole({ rootState }, { character, role }) {
      const data = {
        method: "FightingStandBy",
        token: rootState.User.token,
        character,
        role
      };
      sendMessage(data);
    },

    fightingByStand({ rootState }) {
      const data = {
        method: "FightingByStand",
        token: rootState.User.token
      };
      sendMessage(data);
    },
    lockStage({ commit }, status) {
      commit("SET_FLAG", { key: "stage_lock", status });
    },
    unlockStage({ commit }) {
      // if (state.flags["stage_lock"] == stage) {
        commit("SET_FLAG", { key: "stage_lock", status: null });
      // }
    }
  }
};
